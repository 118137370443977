import React from "react";

export const SwitchBox = ({ status,onClick }) => {
  return (
    <div
      className={`${
        status == 1 ? "bg justify-end" : "bg-slate-300 justify-start"
      } rounded-full w-14 cursor-pointer justify-start flex p-1`}
      onClick={onClick}
    >
      {status == 1 ? (
        <div className="bg-white rounded-full w-5 h-5" />
      ) : (
        <div className="bg-white rounded-full w-5 h-5" />
      )}
    </div>
  );
};

export const Td = ({children,center})=>{
    return (
        <td className={`px-6 py-4 whitespace-nowrap ${center?"flex justify-center":""}`}>
        {children}
      </td>
    )
}
