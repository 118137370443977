import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Center,
  Heading,
  Input,
  Loader,
  MyModal,
  MyPopup,
  Tooltip,
} from "../../../utils/layoutUtils";
import myContext from "../../../context/data/myContext";
import Table from "../../../components/Table";
import { FiEdit, FiEye, FiPlus, FiTrash } from "react-icons/fi";
import { add_links, delete_links } from "../../../utils/apis";
import { toast } from "react-toastify";

const UsefulLinks = () => {
  const context = useContext(myContext);
  const {
    usefulLink,
    loading1,
    getUsefulLinks,
    total_members_page,
    total_members,
    loading,
    setLoading,
  } = context;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [detail, setdetail] = useState({});
  const [edit, setEdit] = useState(false);
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        getUsefulLinks(page, row, search);
      }, 300);
    } else {
      getUsefulLinks(page, row, search);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [row, page, search]);

  const onAdd = async () => {
    if (!detail.Title || !detail.Description || !detail.Url) {
      setdetail({
        ...detail,
        titleMess: !detail.Title ? "Title is required" : "",
        descMess: !detail.Description ? "Description is required" : "",
        urlMess: !detail.Url ? "Url is required" : "",
      });
      return;
    }
    const formdata = new FormData();

    edit
      ? formdata.append("action", "update")
      : formdata.append("action", "create");
    edit && formdata.append("id", detail.ID);
    formdata.append("Title", detail.Title);
    formdata.append("Description", detail.Description);
    formdata.append("Url", detail.Url);
    const res = await add_links(formdata, setLoading);
    if (res?.success) {
      setOpen(false);
      getUsefulLinks(page, row, search);
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };
  const onDelete = async () => {
    const res = await delete_links(detail.ID, setLoading);
    if (res.success) {
      setOpen1(false);
      getUsefulLinks(page, row, search);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Useful Links"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setdetail({});
            setEdit(false);
            setOpen(true);
          }}
        />
      </div>

      <Table
        searchValue={search}
        onSearchChange={(v) => {
          setPage(1);
          setSearch(v.target.value);
          setPrevSearch(search);
        }}
        loading={loading1}
        onRowChange={(v) => {
          setPage(1);
          setRow(v.target.value);
        }}
        total_page={total_members_page}
        page={page}
        setPage={setPage}
        total_value={total_members}
        row={row}
        pagination={true}
        noData={usefulLink}
        headData={["S.No.", "Title", "Description", "Url", "Actions"]}
      >
        {usefulLink.map((item, index) => (
          <tr className="text-center items-center justify-center" key={index}>
            <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Title}</td>

            <td className="px-6 py-4 whitespace-pre-line min-w-[250px] w-full">
              {item.Description}
              {/* <Tooltip text={item.Description}>{item.Description}</Tooltip> */}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Url}</td>
            <td className="px-6 py-4 whitespace-nowrap ">
              <div className="flex gap-2">
                <FiEdit
                  color="#2b3478"
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setEdit(true);
                    setdetail(item);
                    setOpen(true);
                  }}
                />
                <FiTrash
                  color="#d1262b"
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setdetail(item);
                    setOpen1(true);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </Table>
      <MyModal isOpen={open} setIsOpen={setOpen} title={"Add Useful Links"}>
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <Input
              label={"Title"}
              placeholder={"Title"}
              value={detail.Title}
              onChange={(v) => {
                setdetail({ ...detail, Title: v.target.value, titleMess: "" });
              }}
              error={detail.titleMess}
            />
            <Input
              label={"Url"}
              placeholder={"Url"}
              value={detail.Url}
              onChange={(v) => {
                setdetail({ ...detail, Url: v.target.value, urlMess: "" });
              }}
              error={detail.urlMess}
            />
          </div>
          <div className="flex w-full gap-4">
            <Input
              area={true}
              label={"Description"}
              placeholder={"Description"}
              value={detail.Description}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  Description: v.target.value,
                  descMess: "",
                });
              }}
              error={detail.descMess}
            />
            <div className="flex-1" />
          </div>
          <Center>
            <Button
              title={edit ? "Update" : "Submit"}
              onClick={onAdd}
              loading={loading1}
            />
          </Center>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading1}
      ></MyPopup>
    </div>
  );
};

export default UsefulLinks;
