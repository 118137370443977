import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Center,
  FileInput,
  Heading,
  Input,
  Loader,
  MyModal,
  MyPopup,
} from "../../../utils/layoutUtils";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import myContext from "../../../context/data/myContext";
import Table from "../../../components/Table";
import { delete_Gallery_event, get_image_list } from "../../../utils/apis";
import { toast } from "react-toastify";

const Gallery = () => {
  const context = useContext(myContext);
  const {
    gallery_list,
    loading,
    getGalleryList,
    loading1,
    setLoading1,
    total_members_page,
    total_members,
  } = context;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [detail, setdetail] = useState({});
  const [edit, setEdit] = useState(false);
  const [image_list, setImage_list] = useState([]);
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        getGalleryList(page, row, search);
      }, 300);
    } else {
      getGalleryList(page, row, search);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [row, page, search]);

  const onAdd = async () => {
    if (!detail.Title || !detail.Description || !detail.Url) {
      setdetail({
        ...detail,
        titleMess: !detail.Title ? "Title is required" : "",
        descMess: !detail.Description ? "Description is required" : "",
        urlMess: !detail.Url ? "Url is required" : "",
      });
      return;
    }
    const formdata = new FormData();

    edit
      ? formdata.append("action", "update")
      : formdata.append("action", "create");
    edit && formdata.append("id", detail.ID);
    formdata.append("Title", detail.Title);
    formdata.append("Description", detail.Description);
    formdata.append("Url", detail.Url);
    //    const res = await add_links(formdata, setLoading1);

    //    if (res?.success) {
    //      setOpen(false);
    //      getgallery_lists();
    //      toast.success(res?.message);
    //    } else {
    //      toast.error(res?.message);
    //    }
  };
  const onDelete = async () => {
    const res = await delete_Gallery_event(detail.ID, setLoading1);
    if (res.success) {
      setOpen1(false);
      //   getgallery_lists();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const get_images = async (id) => {
    setImage_list([]);
    setLoading1(true);
    const res = await get_image_list(id);
    setLoading1(false);
    if (res.success) {
      setImage_list(res.list);
    } else {
      toast.error(res.message);
    }
  };
  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Gallery"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setdetail({});
            setEdit(false);
            setOpen(true);
          }}
        />
      </div>

      <Table
        searchValue={search}
        onSearchChange={(v) => {
          setPage(1);
          setSearch(v.target.value);
          setPrevSearch(search);
        }}
        loading={loading1}
        onRowChange={(v) => {
          setPage(1);
          setRow(v.target.value);
        }}
        total_page={total_members_page}
        page={page}
        setPage={setPage}
        total_value={total_members}
        row={row}
        pagination={true}
        noData={gallery_list}
        headData={["S.No.", "Event Name", "Poster", "Gallery", "Actions"]}
      >
        {gallery_list.map((item, index) => (
          <tr className="text-center items-center justify-center" key={index}>
            <td className="whitespace-nowrap">{index + 1}</td>
            <td className="whitespace-nowrap">{item.Event_name}</td>

            <td className="p-2 justify-center flex">
              <img
                src={item.Photo_Url}
                className="h-[150px] w-[150px] object-contain"
              />
            </td>
            <td className="whitespace-nowrap text-center">
              <Center>
                <Button
                  title={"View"}
                  onClick={() => {
                    get_images(item.ID);
                    setOpen3(true);
                  }}
                />
              </Center>
            </td>
            <td className="whitespace-nowrap ">
              <div className="flex gap-2 justify-center">
                <FiEdit
                  color="#2b3478"
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setEdit(true);
                    setdetail(item);
                    setOpen(true);
                  }}
                />
                <FiTrash
                  color="#d1262b"
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setdetail(item);
                    setOpen1(true);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </Table>
      <MyModal
        isOpen={open3}
        setIsOpen={setOpen3}
        title={
          !loading1
            ? image_list[0]?.Event_name + " Gallery List"
            : " Gallery List"
        }
      >
        <div className="p-5 w-full">
          <div className="flex justify-end">
            <Button title={"Add"} />
          </div>
          {loading1 ? (
            <Loader />
          ) : (
            <Table noData={image_list} headData={["S.No.", "Image", "Actions"]}>
              {image_list.map((item, index) => (
                <tr
                  className="text-center items-center justify-center"
                  key={index}
                >
                  <td className="whitespace-nowrap">{index + 1}</td>
                  <td className="p-2 justify-center flex">
                    <img
                      src={item.Url}
                      className="h-[150px] w-[150px] object-contain"
                    />
                  </td>

                  <td className="whitespace-nowrap ">
                    <div className="flex gap-2 justify-center">
                      <FiTrash
                        color="#d1262b"
                        size={20}
                        className="cursor-pointer"
                        //   onClick={() => {
                        //     setdetail(item);
                        //     setOpen1(true);
                        //   }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </Table>
          )}
        </div>
      </MyModal>
      <MyModal
        isOpen={open}
        setIsOpen={setOpen}
        title={edit ? "Edit Event" : "Add Event"}
      >
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <Input
              label={"Event Name"}
              placeholder={"Event Name"}
              value={detail.Title}
              onChange={(v) => {
                setdetail({ ...detail, Title: v.target.value, titleMess: "" });
              }}
              error={detail.titleMess}
            />
            <FileInput
              label={"Banner"}
              placeholder={"Banner"}
              value={detail.Url}
              onChange={(v) => {
                setdetail({ ...detail, Url: v.target.value, urlMess: "" });
              }}
              error={detail.urlMess}
            />
          </div>
          {edit || (
            <div className="flex w-full gap-4">
              <FileInput
                area={true}
                label={"Images"}
                placeholder={"Images"}
                value={detail.Description}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    Description: v.target.value,
                    descMess: "",
                  });
                }}
                error={detail.descMess}
              />
              <div className="flex-1" />
            </div>
          )}
          <Center>
            <Button
              title={edit ? "Update" : "Submit"}
              onClick={onAdd}
              loading={loading1}
            />
          </Center>
        </div>
      </MyModal>
      <MyModal
        isOpen={open}
        setIsOpen={setOpen}
        title={edit ? "Edit Event" : "Add Event"}
      >
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <Input
              label={"Event Name"}
              placeholder={"Event Name"}
              value={detail.Title}
              onChange={(v) => {
                setdetail({ ...detail, Title: v.target.value, titleMess: "" });
              }}
              error={detail.titleMess}
            />
            <FileInput
              label={"Banner"}
              placeholder={"Banner"}
              value={detail.Url}
              onChange={(v) => {
                setdetail({ ...detail, Url: v.target.value, urlMess: "" });
              }}
              error={detail.urlMess}
            />
          </div>
          {edit || (
            <div className="flex w-full gap-4">
              <FileInput
                area={true}
                label={"Images"}
                placeholder={"Images"}
                value={detail.Description}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    Description: v.target.value,
                    descMess: "",
                  });
                }}
                error={detail.descMess}
              />
              <div className="flex-1" />
            </div>
          )}
          <Center>
            <Button
              title={edit ? "Update" : "Submit"}
              onClick={onAdd}
              loading={loading1}
            />
          </Center>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading1}
      ></MyPopup>
    </div>
  );
};

export default Gallery;
