import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "../../../components/Table";
import {
  Center,
  Heading,
  Loader,
  MyPopup,
  MyTabs,
} from "../../../utils/layoutUtils";
import { supplier_status_update } from "../../../utils/apis";
import { FiEdit, FiTrash } from "react-icons/fi";
import myContext from "../../../context/data/myContext";
import { toast } from "react-toastify";

const NewSuppliers = () => {
  const [open, setOpen] = useState(false);
  const context = useContext(myContext);
  const {
    suppliers_list,
    total_members_page,
    total_members,
    members_list,
    loading1,
    getSuppliersList,
  } = context;
  const [statusItem, setStatusItem] = useState({});
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        getSuppliersList(page, row, search);
      }, 300);
    } else {
      getSuppliersList(page, row, search);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [row, page, search]);

  const on_submit = async () => {
    try {
      const body = new FormData();
      body.append("action", "Status");
      body.append("id", statusItem?.ID);
      body.append("status", statusItem?.Status == 1 ? 2 : 1);
      const responce = await supplier_status_update(body, setLoading);
      if (responce.success) {
        toast.success(responce.message);
        getSuppliersList(page, row, search);
        setOpen(false);
      } else if (!responce.success) {
        toast.error(responce.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-full block">
      <Heading title={"Suppliers List"} />

      <div>Total Users : {suppliers_list?.length}</div>
      <Table
        searchValue={search}
        onSearchChange={(v) => {
          setPage(1);
          setSearch(v.target.value);
          setPrevSearch(search);
        }}
        loading={loading1}
        onRowChange={(v) => {
          setPage(1);
          setRow(v.target.value);
        }}
        total_page={total_members_page}
        page={page}
        setPage={setPage}
        total_value={total_members}
        row={row}
        pagination={true}
        noData={suppliers_list}
        headData={[
          "S.No.",
          "Services",
          "Full_name",
          "Email_id",
          "Address",
          "State",
          "District",
          "Mobile_Number",
          "Status",
          // "Actions",
        ]}
      >
        {suppliers_list?.map((item, index) => (
          <tr className="text-center items-center justify-center" key={index}>
            <td className="px-6 py-4 whitespace-nowrap">
              {index + 1 + (page - 1) * row}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Services}</td>
            <td className="px-6 py-4 whitespace-pre-line min-w-[250px] w-full">
              {item.Full_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Email_id}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Address}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.State}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.District}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item.Mobile_Number}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div
                className={`${
                  item?.Status == 1
                    ? "bg justify-end"
                    : "bg-slate-300 justify-start"
                } rounded-full w-14 cursor-pointer justify-start flex p-1`}
                onClick={() => {
                  setOpen(true);
                  setStatusItem(item);
                }}
              >
                {item.Status == 1 ? (
                  <div className="bg-white rounded-full w-5 h-5" />
                ) : (
                  <div className="bg-white rounded-full w-5 h-5" />
                )}
              </div>
            </td>
          </tr>
        ))}
      </Table>
      <MyPopup
        isOpen={open}
        setIsOpen={setOpen}
        title={"Change Status"}
        primary={true}
        message={"Are you sure? You want to Change Status"}
        bname={"Submit"}
        onClick={() => on_submit()}
      ></MyPopup>
    </div>
  );
};

export default NewSuppliers;
