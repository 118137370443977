import React, { useState, useEffect } from "react";

import {
  Button,
  Input,
  Heading,
  Spacer,
  ViewData,
  FileInput,
} from "../../../utils/layoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { get_association_api } from "./../../../utils/apis";
import { toast } from "react-toastify";

const AssociationDetail = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setData(state.state);
  }, [state]);


  // action = create(subcategory, Title, Description, image, Role)
  // action = delete(id)
  // action = update (id, subcategory, Title, Description, image, Role)
  // action = list(aid)

  const on_submit = async () => {
    if (
      !data.Name ||
      !data.Title ||
      !data.Description ||
      !data.Role ||
      (state?.type == "add" && !data?.file?.name)
    ) {
      setData({
        ...data,
        NameMess: !data.Name ? "Name is required" : "",
        TitleMess: !data.Title ? "Title is required" : "",
        DescriptionMess: !data.Description ? "Description is required" : "",
        RoleMess: !data.Role ? "Role is required" : "",
        fileMess:
          state?.type == "add" && !data?.file?.name ? "Image is required" : "",
      });
      return;
    }
    try {
      const body = new FormData();
      body.append("action", state?.type == "add" ? "create" : "update");
      body.append("Title", data?.Title);
      body.append("Description", data?.Description);
      body.append("image", data?.file || "");
      body.append("Role", data?.Role);
      if (state?.type == "edit") {
        body.append("id", data?.ID);
      } else if (state?.type == "add") {
        body.append("subcategory", state?.sub_cat_id);
      }

      const res = await get_association_api(body, setLoading);
      if (res.success) {
        toast.success(res.message);
        navigate(-1);
      } else if (!res.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <Heading title={"Association Detail"} />
        <Button
          title={"Back"}
          icon={<IoArrowBack size={20} />}
          onClick={() => navigate(-1)}
        />
      </div>
      <Spacer height={20} />
      <div className="flex gap-4">
        {state?.type == "view" ? (
          <>
            <ViewData title={"Name"} value={data?.Name} />
            <ViewData title={"Title"} value={data?.Title} />
          </>
        ) : (
          <>
            <Input
              label={"Name"}
              placeholder={"Name"}
              value={data.Name}
              onChange={(v) => {
                setData({ ...data, Name: v.target.value, NameMess: "" });
              }}
              error={data.NameMess}
            />
            <Input
              label={"Title"}
              placeholder={"Title"}
              value={data.Title}
              onChange={(v) => {
                setData({ ...data, Title: v.target.value, TitleMess: "" });
              }}
              error={data.TitleMess}
            />
          </>
        )}
      </div>
      <div className="flex gap-4">
        {state?.type == "view" ? (
          <>
            <ViewData title={"Description"} value={data?.Description} />
            <ViewData title={"Role"} value={data?.Role} />
          </>
        ) : (
          <>
            <Input
              area={true}
              label={"Description"}
              placeholder={"Description"}
              value={data.Description}
              onChange={(v) => {
                setData({
                  ...data,
                  Description: v.target.value,
                  DescriptionMess: "",
                });
              }}
              error={data.DescriptionMess}
            />
            <Input
              label={"Role"}
              placeholder={"Role"}
              value={data.Role}
              onChange={(v) => {
                setData({ ...data, Role: v.target.value, RoleMess: "" });
              }}
              error={data.RoleMess}
            />
          </>
        )}
      </div>
      <div className="flex gap-4">
        {state?.type == "view" ? (
          <div className="mb-4 flex-1 block">
            <label htmlFor="email" className="block text-gray-700">
              Photo
            </label>
            <img
              src={data.Photo}
              className="h-[100px] w-[100px] object-contain"
            />
          </div>
        ) : (
          <>
            <FileInput
              doctype={".png,.jpg,.jpeg"}
              label={"Upload Profile Picture"}
              fileName={data.file?.name}
              onChange={(v) => {
                setData({ ...data, file: v.target.files[0], fileMess: "" });
              }}
              error={data.fileMess}
            />
          </>
        )}

        <div className="flex-1" />
      </div>
      {state.type != "view" && (
        <div className={`w-full flex justify-center items-center mt-4`}>
          <Button
            title={state.type == "add" ? "Submit" : "Edit"}
            onClick={on_submit}
          />
        </div>
      )}
    </div>
  );
};

export default AssociationDetail;
