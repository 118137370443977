import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "../../../components/Table";
import {
  Button,
  Center,
  Heading,
  Input,
  Loader,
  MyPopup,
  MyTabs,
  TabBar,
} from "../../../utils/layoutUtils";
import {
  member_status_update,
  supplier_status_update,
} from "../../../utils/apis";
import { FiEdit, FiTrash } from "react-icons/fi";
import { toast } from "react-toastify";
import myContext from "../../../context/data/myContext";

const Users = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);

  const {
    userList,
    loading1,
    setLoading1,
    get_users_list,
    total_members,
    total_members_page,
  } = useContext(myContext);

  async function fetchData(row) {
    const res = await get_users_list(
      currentTab == 1 ? "member" : currentTab == 2 ? "supplier" : "Rejected",
      {
        page: page,
        row: row,
        search: search,
      }
    );
  }
  const save_data = (item, type) => {
    if (item) {
      setData({
        type: type,
        name: item?.PName || item?.Full_name,
        id: item?.ID,
        email: item?.Email_Id || item?.Email_id,
        Mobile_Number: item?.Mobile_Number,
      });
    }
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        fetchData(row);
      }, 300);
    } else {
      fetchData(row);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [currentTab, row, page, search]);

  const on_status_update = async () => {
    try {
      const body = new FormData();
      body.append(
        "action",
        data?.type == "Reject"
          ? "not_approve"
          : data?.type == "Approve"
          ? "approve"
          : ""
      );
      body.append("id", data?.id);
      body.append("name", data?.name);
      body.append("email", data?.email);
      body.append("Mobile_Number", data?.Mobile_Number);
      const responce =
        currentTab == 1
          ? await member_status_update(body, setLoading1)
          : await supplier_status_update(body, setLoading1);
      if (responce?.success) {
        setOpen(false);
        toast.success(responce.message);
        fetchData(row);
      } else if (!responce.success) {
        toast.error(responce.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-full block">
      <Heading title={"User List"} />
      <TabBar
        setActiveTab={setCurrentTab}
        activeTab={currentTab}
        TabData={[
          { name: "Members" },
          { name: "Suppliers" },
          { name: "Rejected Users" },
        ]}
      />
      <div>New Users : {userList?.length}</div>

      <Table
        searchValue={search}
        onSearchChange={(v) => {
          setPage(1);
          setSearch(v.target.value);
          setPrevSearch(search);
        }}
        loading={loading1}
        onRowChange={(v) => {
          setPage(1);
          setRow(v.target.value);
        }}
        total_page={total_members_page}
        page={page}
        setPage={setPage}
        total_value={total_members}
        row={row}
        pagination={true}
        noData={userList}
        headData={
          currentTab == 1
            ? [
                "S.No.",
                "Agency_name",
                "Agency_code",
                "OMC_type",
                "PP",
                "PName",
                "State",
                "District",
                "Email_Id",
                "Mobile_Number",
                "Verification",
              ]
            : [
                "S.No.",
                "Services",
                "Full_name",
                "Email_id",
                "Address",
                "State",
                "District",
                "Mobile_Number",
                "Verification",
              ]
        }
      >
        {userList?.map((item, index) =>
          currentTab == 1 ? (
            <tr className="text-center items-center justify-center" key={index}>
              <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item.Agency_name}
              </td>

              <td className="px-6 py-4 whitespace-pre-line min-w-[250px] w-full">
                {item.Agency_code}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{item.OMC_type}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.PP}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.PName}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.State}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.District}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.Email_Id}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item.Mobile_Number}
              </td>
              <td className="px-6 py-4 whitespace-nowrap flex items-center justify-center gap-2">
                <Button
                  title={"Approve"}
                  bg={"#2b3478"}
                  onClick={() => {
                    setOpen(true);
                    save_data(item, "Approve");
                  }}
                />
                <Button
                  title={"Reject"}
                  bg={"#d1262b"}
                  onClick={() => {
                    setOpen(true);
                    save_data(item, "Reject");
                  }}
                />
              </td>
            </tr>
          ) : (
            <tr className="text-center items-center justify-center" key={index}>
              <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.Services}</td>
              <td className="px-6 py-4 whitespace-pre-line min-w-[250px] w-full">
                {item.Full_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{item.Email_id}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.Address}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.State}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.District}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item.Mobile_Number}
              </td>
              <td className="px-6 py-4 whitespace-nowrap flex items-center justify-center gap-2">
                <Button
                  title={"Approve"}
                  bg={"#2b3478"}
                  onClick={() => {
                    setOpen(true);
                    save_data(item, "Approve");
                  }}
                />
                <Button
                  title={"Reject"}
                  bg={"#d1262b"}
                  onClick={() => {
                    setOpen(true);
                    save_data(item, "Reject");
                  }}
                />
              </td>
            </tr>
          )
        )}
      </Table>

      <MyPopup
        isOpen={open}
        setIsOpen={setOpen}
        title={"Verification"}
        primary={true}
        message={`Are you sure? You want to ${data?.type}`}
        bname={data?.type}
        onClick={() => on_status_update()}
      />
    </div>
  );
};

export default Users;

// action = not_approve(id, name, email);
// action = approve(id, email, name, Mobile_Number);
