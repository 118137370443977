import React, { useState } from "react";
import { Button, Center, Input } from "../../utils/layoutUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const handleLogin = async () => {
    if (!data.email?.trim() || !data.password?.trim()) {
      setData({
        ...data,
        emailMess: !data.email ? "Email is required" : "",
        passwordMess: !data.password ? "Password is required" : "",
      });
      return;
    }

    try {
      const body = new FormData();
      body.append("Email", data.email);
      body.append("Password", data.password);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "login.php",
        {
          method: "POST",
          body: body,
        }
      );
      const res = await responce.json();
      if (res.success) {
        localStorage.setItem("user", JSON.stringify(res));
        toast.success(res.msg);
        navigate("/");
      } else {
        toast.error(res.msg);
      }
    } catch (error) {}
  };

  return (  
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-md w-full px-8 py-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center mb-4">Login</h2>
        <Input
          label={"Email"}
          placeholder={"Email"}
          value={data.email}
          onChange={(e) => {
            setData({ ...data, email: e.target.value, emailMess: "" });
          }}
          error={data.emailMess}
        />
        <Input
          label={"Password"}
          type={"password"}
          placeholder={"Password"}
          value={data.password}
          onChange={(e) => {
            setData({ ...data, password: e.target.value, passwordMess: "" });
          }}
          error={data.passwordMess}
        />
        <Center>
          {" "}
          <Button title={"Login"} onClick={handleLogin} />
        </Center>
      </div>
    </div>
  );
};

export default Login;
