import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Center,
  Heading,
  Loader,
  MyModal,
  MyPopup,
  MyTabs,
  TabBar,
  ViewData,
} from "../../../utils/layoutUtils";
import Table from "../../../components/Table";
import myContext from "../../../context/data/myContext";
import {
  getCategoty,
  get_association_api,
  get_association_list,
} from "../../../utils/apis";
import { useLocation, useNavigate } from "react-router-dom";
import { FiEdit, FiEye, FiPlus, FiTrash } from "react-icons/fi";
import { toast } from "react-toastify";

const AssociationInfo = () => {
  const context = useContext(myContext);
  const {
    category,
    loading,
    setLoading,
    loading1,
    association_info_list,
    get_association_list,
    total_members_page,
    total_members
  } = context;
  const [currentTab, setCurrentTab] = useState(1);
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const navigate = useNavigate();
  const [detail, setDetail] = useState({});
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        get_association_list(currentTab, page, row, search);
      }, 300);
    } else {
      get_association_list(currentTab, page, row, search);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [currentTab,row, page, search]);

  const on_delete = async () => {
    const body = new FormData();
    body.append("id", detail?.ID);
    const res = await get_association_api(body, setLoading);
    if (res.success) {
      toast.success(res.message);
      get_association_list(currentTab, page, row, search);
    } else if (!res.success) {
      toast.error(res.message);
    }
    try {
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Association Info"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            navigate("/association-info/association-detail", {
              state: {
                state: {}, // Your actual state object
                type: "add",
                sub_cat_id: currentTab,
              },
            });
          }}
        />
      </div>
      {/* {loading1 ? (
        <Loader />
      ) : ( */}
        <TabBar
          TabData={category[0]?.sub_categories}
          setActiveTab={setCurrentTab}
          activeTab={currentTab}
        />
      {/* )} */}

      <Table
        searchValue={search}
        onSearchChange={(v) => {
          setPage(1);
          setSearch(v.target.value);
          setPrevSearch(search);
        }}
        loading={loading1}
        onRowChange={(v) => {
          setPage(1);
          setRow(v.target.value);
        }}
        total_page={total_members_page}
        page={page}
        setPage={setPage}
        total_value={total_members}
        row={row}
        pagination={true}
        noData={association_info_list}
        headData={["S.No.", "Name", "Title", "Photo", "Role", "Actions"]}
      >
        {association_info_list.map((item, index) => (
          <tr className="text-center items-center justify-center" key={index}>
            <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Name}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Title}</td>
            <td className="px-6 py-4 flex whitespace-nowrap  justify-center items-center">
              <img src={item.Photo} className="h-16 w-h-16 object-contain" />
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Role}</td>
            <td className="px-6 py-4 whitespace-nowrap ">
              <div className="flex gap-2">
                <FiEye
                  size={20}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate("/association-info/association-detail", {
                      state: {
                        state: item,
                        type: "view",
                        sub_cat_id: currentTab,
                      },
                    })
                  }
                />
                <FiEdit
                  onClick={() =>
                    navigate("/association-info/association-detail", {
                      state: {
                        state: item,
                        type: "edit",
                        sub_cat_id: currentTab,
                      },
                    })
                  }
                  color="#2b3478"
                  size={20}
                  className="cursor-pointer"
                />
                <FiTrash
                  color="#d1262b"
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setDetail(item);
                    setOpen1(true);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </Table>
      <MyModal isOpen={open} setIsOpen={setOpen} title={"Detail"}>
        <div>
          <div className="flex gap-4">
            <ViewData title={"Name"} value={detail?.Name} />
            <ViewData title={"Title"} value={detail?.Title} />
          </div>
          <div className="flex gap-4">
            <ViewData title={"Description"} value={detail?.Description} />
            <ViewData title={"Role"} value={detail?.Role} />
          </div>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={on_delete}
      ></MyPopup>
    </div>
  );
};

export default AssociationInfo;
