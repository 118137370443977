
export const get_insurance_list = async (cid, sid, endpoint, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "list");
    formdata.append("cid", cid);
    formdata.append("sid", sid);
    const responce = await fetch(process.env.REACT_APP_API_KEY + endpoint, {
      method: "POST",
      body: formdata,
    });
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const get_association_list = async (id, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "list");
    formdata.append("aid", id);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "association_info.php",
      { method: "POST", body: formdata }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const get_association_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "association_info.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const get_advice_list = async (cid, sid, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "list");
    formdata.append("cid", cid);
    formdata.append("sid", sid);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "advice_legal.php",
      { method: "POST", body: formdata }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const delete_advice = async (id, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "delete");
    formdata.append("id", id);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "advice_legal.php",
      { method: "POST", body: formdata }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const delete_links = async (id, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "delete");
    formdata.append("id", id);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "useful_links.php",
      { method: "POST", body: formdata }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const add_links = async (formdata, setLoading) => {
  try {
    setLoading(true);
    // const formdata = new FormData();
    // formdata.append("action", "create");
    // formdata.append("id", id);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "useful_links.php",
      { method: "POST", body: formdata }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const delete_Gallery_event = async (id, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "delete");
    formdata.append("id", id);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "Gallery.php",
      { method: "POST", body: formdata }
    );
    const res = await responce.json();

    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const get_image_list = async (id) => {
  try {
    // setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "list");
    formdata.append("gid", id);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "Gallery_details.php",
      { method: "POST", body: formdata }
    );
    const res = await responce.json();

    // setLoading(false);
    return res;
  } catch (error) {
    // setLoading(false);
  }
};
export const delete_blogs = async (id, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "delete");
    formdata.append("id", id);
    const responce = await fetch(process.env.REACT_APP_API_KEY + "blog.php", {
      method: "POST",
      body: formdata,
    });
    const res = await responce.json();

    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const addEdit_Gallery_event = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "Gallery.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();

    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const addEdit_events = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(process.env.REACT_APP_API_KEY + "Event.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();

    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const delete_Events = async (id, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "delete");
    formdata.append("id", id);
    const responce = await fetch(process.env.REACT_APP_API_KEY + "Event.php", {
      method: "POST",
      body: formdata,
    });
    const res = await responce.json();

    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const delete_Downloads = async (id, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "delete");
    formdata.append("id", id);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "Downloads.php",
      {
        method: "POST",
        body: formdata,
      }
    );
    const res = await responce.json();

    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const get_training_list = async (cid, sid, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "list");
    formdata.append("category_id", cid);
    formdata.append("sub_category_id", sid);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "Ask_for_training.php",
      { method: "POST", body: formdata }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const delete_training = async (id, setLoading) => {
  try {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("action", "delete");
    formdata.append("id", id);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "Ask_for_training.php",
      {
        method: "POST",
        body: formdata,
      }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

// Started by Naveen

export const member_status_update = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(process.env.REACT_APP_API_KEY + "Member.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const supplier_status_update = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(
      process.env.REACT_APP_API_KEY + "suppliers.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const add_event_entity_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(process.env.REACT_APP_API_KEY + "Member.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
