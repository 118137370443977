import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Heading,
  Loader,
  MyPopup,
  MyTabs,
  TabBar,
} from "../../../utils/layoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import myContext from "../../../context/data/myContext";
import Table from "../../../components/Table";
import { delete_advice, get_advice_list } from "../../../utils/apis";
import { FiTrash } from "react-icons/fi";
import { toast } from "react-toastify";

const GetAdvice = () => {
  const context = useContext(myContext);
  const {
    category,
    loading,
    setLoading,
    loading1,
    advice_list,
    get_advice_list,
    total_members_page,
    total_members,
  } = context;

  const [currentTab, setCurrentTab] = useState(1);
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [search, setSearch] = useState("");
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);

  const navigate = useNavigate();
  const [detail, setDetail] = useState({});
  async function fetchData() {
    return get_advice_list(
      category[3]?.id,
      category[3]?.sub_categories[currentTab - 1]?.id,
      page,
      row,
      search
    );
  }

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        fetchData();
      }, 300);
    } else {
      fetchData();
    }
    return () => {
      clearTimeout(delay);
    };
  }, [currentTab, row, page, search]);

  const onDelete = async () => {
    const res = await delete_advice(detail.ID, setLoading2);
    if (res.success) {
      setOpen(false);
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="w-full block">
      <Heading title={"Get Advice List"} />
      <TabBar
        TabData={category[3]?.sub_categories}
        setActiveTab={setCurrentTab}
        activeTab={currentTab}
      />
      <Table
        searchValue={search}
        onSearchChange={(v) => {
          setPage(1);
          setSearch(v.target.value);
          setPrevSearch(search);
        }}
        loading={loading1}
        onRowChange={(v) => {
          setPage(1);
          setRow(v.target.value);
        }}
        total_page={total_members_page}
        page={page}
        setPage={setPage}
        total_value={total_members}
        row={row}
        pagination={true}
        noData={advice_list}
        headData={["S.No.", "Title", "Description", "Photo", "Actions"]}
      >
        {advice_list.map((item, index) => (
          <tr className="text-center items-center justify-center" key={index}>
            <td className="whitespace-nowrap">{index + 1}</td>
            <td className="whitespace-nowrap">{item.Title}</td>
            <td className="whitespace-nowrap">{item.Description}</td>
            <td className="p-2 justify-center flex">
              <img
                src={item.Document}
                className="h-[150px] w-[150px] object-contain"
              />
            </td>

            <td className="whitespace-nowrap ">
              <div className="flex gap-2 justify-center">
                <FiTrash
                  color="#d1262b"
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setDetail(item);
                    setOpen(true);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </Table>
      <MyPopup
        isOpen={open}
        setIsOpen={setOpen}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading2}
      ></MyPopup>
    </div>
  );
};

export default GetAdvice;
