import React, { useContext, useEffect, useRef, useState } from "react";
import myContext from "../../../context/data/myContext";
import {
  Button,
  Center,
  Heading,
  Input,
  Loader,
  MyModal,
  MyPopup,
  SelectBox,
  TabBar,
} from "../../../utils/layoutUtils";
import Table from "../../../components/Table";
import {
  FiEdit,
  FiPlus,
  FiToggleLeft,
  FiToggleRight,
  FiTrash,
} from "react-icons/fi";
import { member_status_update } from "../../../utils/apis";
import { toast } from "react-toastify";
import { validateEmail, validateMobile } from "../../../utils/Utils";
// import { FiEdit} from "react-icons/fi";

const NewMembers = () => {
  const {
    members_list,
    loading1,
    getMembersList,
    getDstrict_list,
    District_list,
    total_mem_new,
total_mem_new_page
  } = useContext(myContext);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [statusItem, setStatusItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);

  useEffect(() => {
    // getDstrict();
    getDstrict_list();
  }, []);

  useEffect(() => {
    // getDstrict();
    getMembersList(page, row, search);
  }, [page, row]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        getMembersList(page, row, search);
      }, 300);
    } else {
      getMembersList(page, row, search);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [ search]);

  const on_submit = async () => {
    try {
      const body = new FormData();
      body.append("action", "Status");
      body.append("id", statusItem?.ID);
      body.append("status", statusItem?.Status == 1 ? 2 : 1);
      const responce = await member_status_update(body, setLoading);
      if (responce.success) {
        toast.success(responce.message);
        getMembersList(page, row, search);
        setOpen(false);
      } else if (!responce.success) {
        toast.error(responce.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onEdit = async () => {
    if (
      !editItem?.Agency_ID ||
      editItem?.Agency_ID?.length < 6 ||
      !editItem?.OMC_Type ||
      !editItem?.Agency_name ||
      !editItem?.Partner_name ||
      !editItem?.District ||
      !editItem?.Email_Address ||
      !editItem?.Mobile_Number ||
      !validateEmail(editItem?.Email_Address) || // Validate email format
      !validateMobile(editItem?.Mobile_Number) // Validate 10-digit mobile number
    ) {
      setEditItem({
        ...editItem,
        Agency_IDMess: !editItem?.Agency_ID
          ? "Agency Id is required"
          : editItem?.Agency_ID?.length < 6
          ? "Please enter valid Agency ID"
          : "",
        OMC_TypeMess: !editItem?.OMC_Type ? "OMC type is required" : "",
        Agency_nameMess: !editItem?.Agency_name
          ? "Agency Name is required"
          : "",
        Partner_nameMess: !editItem?.Partner_name
          ? "Partner Name is required"
          : "",
        DistrictMess: !editItem?.District ? "District is required" : "",
        Email_AddressMess: !editItem?.Email_Address
          ? "Email Address is required"
          : !validateEmail(editItem?.Email_Address)
          ? "Invalid Email Address"
          : "",
        Mobile_NumberMess: !editItem?.Mobile_Number
          ? "Mobile Number is required"
          : !validateMobile(editItem?.Mobile_Number)
          ? "Please enter valid mobile number"
          : "",
      });
      return;
    }

    try {
      const body = new FormData();
      body.append(
        "action",
        editItem?.type == "add" ? "add_member" : "update_member"
      );
      if (editItem?.type == "edit") {
        body.append("id", editItem?.ID);
      }
      body.append("agency_id", editItem?.Agency_ID);
      body.append("omc_type", editItem?.OMC_Type);
      body.append("agency_name", editItem?.Agency_name);
      body.append("district", editItem?.District);
      body.append("state", 1);
      body.append("mobile_number", editItem?.Mobile_Number);
      body.append("email_address", editItem?.Email_Address);
      body.append("partner_name", editItem?.Partner_name);
      const responce = await member_status_update(body, setLoading);
      if (responce.success) {
        toast.success(responce.message);
        getMembersList(page, row, search);
        setOpen1(false);
        setEditItem({});
      } else if (!responce.success) {
        toast.error(responce.message);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between flex-col ">
        <div className="flex justify-between gap-2">
          <Heading title={`Members List`} />

          <Button
            title={"Add"}
            icon={<FiPlus size={20} />}
            onClick={() => {
              setOpen1(true);
              setEditItem({ type: "add" });
            }}
          />
        </div>
        <div>Total Users : {members_list?.length}</div>
      </div>
      {/* <Heading title={"Members List"} /> */}

      <Table
        searchValue={search}
        onSearchChange={(v) => {
          setPage(1);
          setSearch(v.target.value);
          setPrevSearch(search);
        }}
        loading={loading1}
        onRowChange={(v) => {
          setPage(1);
          setRow(v.target.value);
        }}
        total_page={total_mem_new_page}
        page={page}
        setPage={setPage}
        total_value={total_mem_new}
        row={row}
        pagination={true}
        noData={members_list}
        setRow={setRow}
        headData={[
          "S.No.",
          "Agency name",
          "Agency code",
          "OMC type",
          "PName",
          "State",
          "District",
          "Email Id",
          "Mobile Number",
          "Status",
          "Action",
        ]}
      >
        {members_list?.map((item, index) => (
          <tr className="text-center items-center justify-center" key={index}>
            <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Agency_name}</td>

            <td className="px-6 py-4 whitespace-pre-line min-w-[250px] w-full">
              {item.Agency_ID}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.OMC_Type}</td>
            {/* <td className="px-6 py-4 whitespace-nowrap">{item.PP}</td> */}
            <td className="px-6 py-4 whitespace-nowrap">{item.Partner_name}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.State || "Telangana"}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.district}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item.Email_Address}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item.Mobile_Number}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div
                className={`${
                  item?.Status == 1
                    ? "bg justify-end"
                    : "bg-slate-300 justify-start"
                } rounded-full w-14 cursor-pointer justify-start flex p-1`}
                onClick={() => {
                  setOpen(true);
                  setStatusItem(item);
                }}
              >
                {item.Status == 1 ? (
                  <div className="bg-white rounded-full w-5 h-5" />
                ) : (
                  <div className="bg-white rounded-full w-5 h-5" />
                )}
              </div>
            </td>
            <td>
              <div className="flex gap-2 justify-center items-center">
                <FiEdit
                  onClick={() => {
                    setOpen1(true);
                    setEditItem({ type: "edit", ...item });
                  }}
                  color="#2b3478"
                  size={20}
                  className="cursor-pointer"
                />
              </div>
            </td>
          </tr>
        ))}
      </Table>

      <MyModal
        isOpen={open1}
        setIsOpen={setOpen1}
        title={`${editItem?.type == "add" ? "Add" : "Edit"} Member`}
      >
        <div className="p-5 w-full">
          <div className="flex w-full gap-4 ">
            <Input
              label={"Agency ID"}
              placeholder={"Agency ID"}
              value={editItem.Agency_ID}
              onChange={(v) => {
                const input = v.target.value.replace(/\D/g, "");
                if (input?.length <= 8) {
                  setEditItem({
                    ...editItem,
                    Agency_ID: input,
                    Agency_IDMess: "",
                  });
                }
              }}
              error={editItem.Agency_IDMess}
            />
            {/* <Input
              label={"OMC TYPE"}
              placeholder={"OMC TYPE"}
              value={editItem.OMC_Type}
              onChange={(v) => {
                setEditItem({
                  ...editItem,
                  OMC_Type: v.target.value,
                  OMC_TypeMess: "",
                });
              }}
              error={editItem.OMC_TypeMess}
             
            /> */}

            <SelectBox
              label={"OMC TYPE"}
              placeholder={"OMC TYPE"}
              value={editItem.OMC_Type}
              onChange={(v) => {
                setEditItem({
                  ...editItem,
                  OMC_Type: v.target.value,
                  OMC_TypeMess: "",
                });
              }}
              error={editItem.OMC_TypeMess}
              options={
                <>
                  <option value={"HPCL"}>HPCL</option>
                  <option value={"BPCL"}>BPCL</option>
                  <option value={"IOCL"}>IOCL</option>
                </>
              }
            />
          </div>
          <div className="flex w-full gap-4 ">
            <Input
              label={"Agency Name"}
              placeholder={"Agency Name"}
              value={editItem.Agency_name}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");

                setEditItem({
                  ...editItem,
                  Agency_name: input,
                  Agency_nameMess: "",
                });
              }}
              error={editItem.Agency_nameMess}
            />
            <Input
              label={"Propriter Name"}
              placeholder={"Propriter Name"}
              value={editItem.Partner_name}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setEditItem({
                  ...editItem,
                  Partner_name: input,
                  Partner_nameMess: "",
                });
              }}
              error={editItem.Partner_nameMess}
            />
          </div>
          <div className="flex w-full gap-4 ">
            <Input
              label={"State"}
              placeholder={"State"}
              value={"Telangana"}
              disabled={true}
            />

            <SelectBox
              label={"District"}
              placeholder={"District"}
              value={editItem.District}
              onChange={(v) => {
                setEditItem({
                  ...editItem,
                  District: v.target.value,
                  DistrictMess: "",
                });
              }}
              options={District_list?.map((v, i) => (
                <option key={i} value={v?.id}>
                  {v?.district_names}
                </option>
              ))}
              error={editItem.DistrictMess}
            />
          </div>
          <div className="flex w-full gap-4 ">
            <Input
              label={"Email"}
              placeholder={"Email"}
              value={editItem.Email_Address}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, "");
                setEditItem({
                  ...editItem,
                  Email_Address: input,
                  Email_AddressMess: "",
                });
              }}
              error={editItem.Email_AddressMess}
            />
            <Input
              label={"Mobile"}
              placeholder={"Mobile"}
              value={editItem.Mobile_Number}
              onChange={(v) => {
                const input = v.target.value.replace(/\D/g, "");
                if (input?.length <= 10) {
                  setEditItem({
                    ...editItem,
                    Mobile_Number: input,
                    Mobile_NumberMess: "",
                  });
                }
              }}
              error={editItem.Mobile_NumberMess}
            />
          </div>
          <Center>
            <Button title={"Submit"} onClick={onEdit} loading={loading} />
          </Center>
        </div>
      </MyModal>

      <MyPopup
        isOpen={open}
        setIsOpen={setOpen}
        title={"Change Status"}
        primary={true}
        message={"Are you sure? You want to Change Status"}
        bname={"Submit"}
        onClick={() => on_submit()}
      ></MyPopup>
      {/* <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete member data"}
        bname={"Delete"}
        // onClick={() => onDelete()}
      ></MyPopup> */}
    </div>
  );
};

export default NewMembers;
