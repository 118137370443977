import { toast } from "react-toastify";

export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
  return emailRegex.test(email);
}

// Function to validate mobile number
// export function validateMobile(mobile) {
//   const mobileRegex = /^\d{10}$/; // Regex for 10-digit number
//   return mobileRegex.test(mobile);
// }

export function validateMobile(mobile) {
  const mobileRegex = /^[6-9]\d{9}$/; // 10-digit number, starting with 6-9
  return mobileRegex.test(mobile);
}

export const downloadFile = (
  filePath,
  message = "File Downloaded Successfully"
) => {
  const link = document.createElement("a");
  link.href = filePath;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  toast.success(message);
};

export const EventDetailsHeaders = [
  {
    type: 1,
    header: [
      "S.No.",
      "OMC Type",
      "Agency Name",
      "Agency Code",
      "District",
      "Mobile",
      "Email",
      "Action",
    ],
  },
  {
    type: 2,
    header: [
      "S.No.",
      "Full Name",
      "Company Name",
      "Designation",
      "Mobile",
      "Email",
      "Transaction Id",
      "Payment Type",
      "Action",
    ],
  },
  {
    type: 3,
    header: [
      "S.No.",
      "Full Name",
      "Company Name",
      "Role",
      "Mobile",
      "Email",
      "Registration no.",
      //   "Status",
      "Action",
    ],
  },
  {
    type: 4,
    header: [
      "S.No.",
      "Full Name",
      "Media Type",
      "Channel / Publication Name",
      "Mobile",
      "Email",
      "Action",
    ],
  },
];
