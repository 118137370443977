import React, { useContext, useState } from "react";
import { Heading, Loader, MyPopup } from "../../../utils/layoutUtils";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import myContext from "../../../context/data/myContext";
import Table from "../../../components/Table";
import { delete_Gallery_event, delete_blogs } from "../../../utils/apis";
import { toast } from "react-toastify";

const Blogs = () => {
  const context = useContext(myContext);
  const { blogs_list, loading, getBlogsList } = context;
  const [open1, setOpen1] = useState(false);
  const [detail, setdetail] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [search, setSearch] = useState("");

  const onDelete = async () => {
    const res = await delete_blogs(detail.ID, setLoading1);
    if (res.success) {
      setOpen1(false);
      getBlogsList();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Blogs"} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={blogs_list}
          headData={["S.No.", "Name", "Title", "Messages", "Banner", "Actions"]}
        >
          {blogs_list
            ?.filter((item) => {
              return (
                item.Name.toLowerCase().includes(search.toLowerCase()) ||
                item.Title.toLowerCase().includes(search.toLowerCase()) ||
                item.Messages.toLowerCase().includes(search.toLowerCase())
              );
            })
            .map((item, index) => (
              <tr
                className="text-center items-center justify-center"
                key={index}
              >
                <td className="whitespace-nowrap">{index + 1}</td>
                <td className="whitespace-nowrap">{item.Name}</td>
                <td className="whitespace-nowrap">{item.Title}</td>
                <td className="whitespace-nowrap">{item.Messages}</td>
                <td className="p-2 justify-center flex">
                  <img
                    src={item.Url}
                    className="h-[150px] w-[150px] object-contain"
                  />
                </td>

                <td className="whitespace-nowrap ">
                  <div className="flex gap-2 justify-center">
                    <FiTrash 
color="#d1262b"
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setOpen1(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}

      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading1}
      ></MyPopup>
    </div>
  );
};

export default Blogs;
