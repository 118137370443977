// Sidebar.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ props }) => {
  const navigate = useNavigate();
  return (
    <>
      <Tab title={"Dashboard"} onClick={() => navigate("/")} path="" />
      <Tab title={"Users"} onClick={() => navigate("/users")} path="users" />
      <Tab
        title={"Members"}
        onClick={() => {
          navigate("/members");
        }}
        path="members"
      />
      <Tab
        title={"Suppliers"}
        onClick={() => navigate("/suppliers")}
        path="suppliers"
      />
      <Tab
        title={"Association Info"}
        onClick={() =>
          navigate("/association-info", { state: "Association Info" })
        }
        path="association-info"
      />
      <Tab
        title={"Useful Links"}
        onClick={() => navigate("/useful-links")}
        path="useful-links"
      />
      <Tab
        title={"Gallery"}
        onClick={() => navigate("/gallery")}
        path="gallery"
      />
      <Tab
        title={"Get Advice"}
        onClick={() => navigate("/get-advice", { state: "Get Advice" })}
        path="get-advice"
      />
      <Tab
        title={"Get Legal Assistance"}
        onClick={() =>
          navigate("/get-legal-assistance", { state: "Get Legal Assistance" })
        }
        path="get-legal-assistance"
      />
      <Tab
        title={"Mechanic/MI"}
        // onClick={() => navigate("/mechanic")}
        path="mechanic"
      />
      <Tab
        title={"Let's Shop"}
        // onClick={() => navigate("/product")}
        path="product"
      />
      <Tab
        title={"Buy Insurance"}
        onClick={() => navigate("/buy-insurance", { state: "Buy Insurance" })}
        path="buy-insurance"
      />
      <Tab
        title={"Ask for Training"}
        onClick={() => navigate("/ask-for-training")}
        path="ask-for-training"
      />
      <Tab title={"Events"} onClick={() => navigate("/events")} path="events" />
      <Tab title={"Blogs"} onClick={() => navigate("/blogs")} path="blogs" />
      <Tab
        title={"Downloads"}
        onClick={() => navigate("/downloads")}
        path="downloads"
      />
      <Tab
        title={"Notification"}
        onClick={() => navigate("/notification")}
        path="notification"
      />
    </>
  );
};

export default Sidebar;

export const Tab = ({ title, icon, onClick, path = "" }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const { pathname } = useLocation();

  return (
    <div
      className={classNames(
        "px-2 py-1 m-2 flex items-center font-normal text-lg cursor-pointer  ",
        pathname?.split("/")[1] == path
          ? "text-white bg font-extrabold shadow"
          : "text-gray-500 hover:text-gray-600 hover:bg-slate-300"
      )}
      style={{}}
      onClick={onClick}
    >
      {icon}
      {title}
    </div>
  );
};
