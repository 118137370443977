import React from "react";

const PassPrint = ({ previewRef, data }) => {
  return (
    <div ref={previewRef} className="a6-page text-center ">
      <div className="mt-[300px]" />
      <div className="p-10 ">
        <div className="text-left uppercase">
          <p className="mb-1 text-[14px]">
            <span className="font-bold">
              {data?.active == 1 ? "Agency Name" : "Name"} : 
            </span> {data?.agency_name}
          </p>
          {data?.media_type && (
            <p className="mb-1 text-[14px]">
              <span className="font-bold">Media Type : </span> {data?.media_type}
            </p>
          )}
          <p className="mb-1 text-[14px]">
            <span className="font-bold">
              {data?.active == 1
                ? "Oil Company"
                : data?.active == 2 || data?.active == 3
                ? "Company Name"
                : data?.active == 4
                ? data?.media_type_name
                : ""} : 
              {/* Oil Company:  */} 
            </span>  {data?.omc_type}
           
          </p>
          {data?.active != 4 &&
           <p className="text-[14px]">
           <span className="font-bold">
             {data?.active == 1 ? "District" :
             data?.active == 2 ? "Designation" :
             data?.active == 3 ? "Role" :
             data?.active == 4 ? "" :
             ""} : 
           </span> {data?.district}
           
         </p>
          }
         
        </div>
      </div>

      {/* Member/Visitor/Exhibitor Section */}
      {/* <div className="border border-black p-2 mt-10 ">
        <h3 className="font-bold text-[24px] uppercase">{ data?.active == 1
                ? "MEMBER"
                : data?.active == 2
                ? "VISITOR"
                : data?.active == 3
                ? "EXBHITOR"
                : data?.active == 4
                ? "MEDIA"
                : ""}</h3>
      </div> */}
    </div>
  );
};

export default PassPrint;
