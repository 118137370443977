import React from 'react'
import ReactToPrint from "react-to-print";


export const ReactPrintData = ({ setViewItem, v, documentTitle, previewRef, num }) => {
    return (
      <div>
        <ReactToPrint
          pageStyle={`
            @page {
              size: 4.1in 5.8in;
              margin: 0; /* Removes default header and footer */
            }
            body {
              margin: 0; /* Ensures there is no additional margin around the content */
            }
          `}
          trigger={() => (
            <div
              onMouseEnter={() => {
                setViewItem(v);
              }}
              onTouchStart={() => {
                setViewItem(v);
              }}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setViewItem(v);
              }}
            >
              {num}
            </div>
          )}
          content={() => previewRef.current}
          documentTitle={documentTitle}
        />
      </div>
    );
  };
  
  
//   export default ReactPrint;