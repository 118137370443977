import React, { useEffect, useState } from "react";
import MyContext from "./myContext";
import { toast } from "react-toastify";

function MyState(props) {
  const [category, setCategory] = useState({});
  const [usefulLink, setUsefulLink] = useState([]);
  const [gallery_list, setGallery_list] = useState([]);
  const [blogs_list, setBlogs_list] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members_list, setMembers_list] = useState([]);
  const [total_members, setTotal_members] = useState(0);
  const [total_members_page, setTotal_members_page] = useState(0);
  const [suppliers_list, setSuppliers_list] = useState([]);
  const [events_list, setEvents_list] = useState([]);
  const [events_list_details, setEvents_list_details] = useState([]);
  const [download_list, setDownload_list] = useState([]);

  const [total_att, setTotal_att] = useState(0);
  const [total_att_page, setTotal_att_page] = useState(0);

  const [total_mem_new, setTotal_mem_new] = useState(0);
  const [total_mem_new_page, setTotal_mem_new_page] = useState(0);

  // NP Start

  const [userList, setUserList] = useState([]);
  const [association_info_list, setAssociation_info_list] = useState([]);
  const [advice_list, setAdvice_list] = useState([]);
  const [training_list, setTraining_list] = useState([]);
  const [insurance_list, setInsurance_list] = useState([]);

  const [District_list, setDistrict_list] = useState([]);

  const getDstrict_list = async () => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      const responce = await fetch(
        process.env.REACT_APP_API_KEY2 + "distric.php",
        {
          method: "POST",
        }
      );
      const res = await responce.json();

      setLoading1(false);
      if (res.status == 200) {
        setDistrict_list(res.record);
      } else {
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const get_users_list = async (type, obj) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", type);
      formdata.append("page", obj?.page);
      formdata.append("limit", obj?.row);
      formdata.append("search", obj?.search);

      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "userlist.php",
        {
          method: "POST",
          body: formdata,
        }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setUserList(res.data);
        setTotal_members(res.totalRecords);
        setTotal_members_page(res.totalPages);
      } else {
        setUserList([]);
        setTotal_members(0);
        setTotal_members_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const getCategoty = async () => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "Category.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setCategory(res.categories);
      } else {
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  const getUsefulLinks = async (page, limit, search) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("search", search);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "useful_links.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setUsefulLink(res.data);
        setTotal_members(res?.totalRecords || res?.list?.length);
        setTotal_members_page(res?.totalPages || res?.list?.length / limit);
      } else {
        setUsefulLink([]);
        setTotal_members(0);
        setTotal_members_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  const getGalleryList = async (page, limit, search) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("search", search);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "Gallery.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setGallery_list(res.data);
        setTotal_members(res?.totalRecords || res?.list?.length);
        setTotal_members_page(res?.totalPages || res?.list?.length / limit);
      } else {
        setGallery_list([]);
        setTotal_members(0);
        setTotal_members_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const get_advice_list = async (cid, sid, page, limit, search) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      formdata.append("cid", cid);
      formdata.append("sid", sid);
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("search", search);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "advice_legal.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setAdvice_list(res.data);
        setTotal_members(res?.totalRecords || res?.list?.length);
        setTotal_members_page(res?.totalPages || res?.list?.length / limit);
      } else {
        setAdvice_list([]);
        setTotal_members(0);
        setTotal_members_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const get_training_list = async (cid, sid, page, limit, search) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      formdata.append("category_id", cid);
      formdata.append("sub_category_id", sid);
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("search", search);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "Ask_for_training.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setTraining_list(res.data);
        setTotal_members(res?.totalRecords || res?.list?.length);
        setTotal_members_page(res?.totalPages || res?.list?.length / limit);
      } else {
        setTraining_list([]);
        setTotal_members(0);
        setTotal_members_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const get_insurance_list = async (
    cid,
    sid,
    endpoint,
    page,
    limit,
    search
  ) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      formdata.append("cid", cid);
      formdata.append("sid", sid);
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("search", search);
      const responce = await fetch(process.env.REACT_APP_API_KEY + endpoint, {
        method: "POST",
        body: formdata,
      });
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setInsurance_list(res.data);
        setTotal_members(res?.totalRecords || res?.list?.length);
        setTotal_members_page(res?.totalPages || res?.list?.length / limit);
      } else {
        setInsurance_list([]);
        setTotal_members(0);
        setTotal_members_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const getBlogsList = async () => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      const responce = await fetch(process.env.REACT_APP_API_KEY + "blog.php", {
        method: "POST",
        body: formdata,
      });
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setBlogs_list(res.list);
      } else {
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  const getMembersList = async (page, limit, search) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("search", search);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "Member.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setMembers_list(res.Member_details);
        setTotal_mem_new(res.totalRecords);
        setTotal_mem_new_page(res.totalPages);
      } else {
        setMembers_list([]);
        setTotal_mem_new(0);
        setTotal_mem_new_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  const getSuppliersList = async (page, limit, search) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "supplier");
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("search", search);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "userlist.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setSuppliers_list(res.data);
        setTotal_members(res.totalRecords);
        setTotal_members_page(res.totalPages);
      } else {
        setSuppliers_list([]);
        setTotal_members(0);
        setTotal_members_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const get_association_list = async (id, page, limit, search) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      formdata.append("aid", id);
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("search", search);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "association_info.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setAssociation_info_list(res?.data);
        setTotal_members(res?.totalRecords || res?.list?.length);
        setTotal_members_page(res?.totalPages || res?.list?.length / limit);
      } else {
        setAssociation_info_list([]);
        setTotal_members(0);
        setTotal_members_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const getEventsList = async () => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "list");
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "Event.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setEvents_list(res.data);
      } else {
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const getEventsDetails = async ({ type, page, limit, id, search = "" }) => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "get_attendance");
      formdata.append("type", type);
      formdata.append("page", page);
      formdata.append("limit", limit);
      formdata.append("event_id", id);
      formdata.append("search", search);
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "Event.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setEvents_list_details(res?.data);
        setTotal_att(res?.totalcount);
        setTotal_att_page(Math.ceil(res?.totalcount / limit));
      } else {
        setEvents_list_details([]);
        setTotal_att(0);
        setTotal_att_page(0);
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  const getDownloadList = async () => {
    try {
      setLoading1(true);
      const formdata = new FormData();
      formdata.append("action", "fetch");
      const responce = await fetch(
        process.env.REACT_APP_API_KEY + "Downloads.php",
        { method: "POST", body: formdata }
      );
      const res = await responce.json();
      setLoading1(false);

      if (res.success) {
        setDownload_list(res.knowledge);
      } else {
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  // const getTrainingList = async () => {
  //   try {
  //     setLoading1(true);
  //     const formdata = new FormData();
  //     formdata.append("action", "list");
  //     const responce = await fetch(
  //        process.env.REACT_APP_API_KEY + "Ask_for_training.php",
  //       { method: "POST", body: formdata }
  //     );
  //     const res = await responce.json();
  //     setLoading1(false);

  //     if (res.success) {
  //       setDownload_list(res.knowledge);
  //     } else {
  //     }
  //   } catch (error) {
  //     setLoading1(false);
  //   }
  // };

  useEffect(() => {
    getDownloadList();
    getEventsList();
    getSuppliersList(1, 10, "");
    getMembersList(1, 10, "");
    getCategoty();
    getUsefulLinks(1, 10, "");
    getGalleryList();
    getBlogsList();

    // Np Start
    get_users_list("member", {
      page: 1,
      row: 10,
      search: "",
    });
    get_association_list(1, 10);
  }, []);

  return (
    <MyContext.Provider
      value={{
        total_members,
        total_members_page,
        loading,
        setLoading,
        loading1,
        setLoading1,

        // Function and Lists

        getSuppliersList,
        suppliers_list,
        getMembersList,
        members_list,
        getCategoty,
        category,
        getUsefulLinks,
        usefulLink,
        getGalleryList,
        gallery_list,
        getBlogsList,
        blogs_list,
        getEventsList,
        events_list,
        getEventsList,
        download_list,
        getDownloadList,
        userList,
        get_users_list,
        association_info_list,
        get_association_list,
        advice_list,
        get_advice_list,
        training_list,
        get_training_list,
        insurance_list,
        get_insurance_list,
        District_list,
        getDstrict_list,

        getEventsDetails,
        events_list_details,
        total_att,
        total_att_page,
        total_mem_new,
        total_mem_new_page,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
}

export default MyState;
