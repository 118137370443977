import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Login from "./screens/auth/Login";
import Layout from "./Layout";
import Users from "./screens/home/users/Users";
import Dashboard from "./screens/home/dashboard/Dashboard";
import AssociationInfo from "./screens/home/association_Info";
import UsefulLinks from "./screens/home/useful_Links";
import MyState from "./context/data/myState";
import AssociationDetail from "./screens/home/association_Info/AssociationDetail";
import Gallery from "./screens/home/gallery/indesx";
import GetAdvice from "./screens/home/get_Advice";
import GetLegalAssistance from "./screens/home/get_Legal_Assistance";
import Blogs from "./screens/home/blogs";
import BuyInsurance from "./screens/home/buy_Insurance";
import NewMembers from "./screens/home/users/NewMembers";
import NewSuppliers from "./screens/home/users/NewSuppliers";
import Events from "./screens/home/events";
import Downloads from "./screens/home/downloads";
import AskTraining from "./screens/home/ask_for_Training";
import Notifications from "./screens/home/notification";
import { FullLoader } from "./utils/layoutUtils";
import { useContext, useEffect } from "react";
import myContext from "./context/data/myContext";
import EventDetails from "./screens/home/events/EventDetails";
import ScrollToTop from "./utils/Hook/ScrollToTop";

function App() {
//  const {pathname}  =useLocation()
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

  return (
    <MyState>
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/association-info" element={<Outlet />}>
                <Route index element={<AssociationInfo />} />
                <Route
                  path="/association-info/association-detail"
                  element={<AssociationDetail />}
                />
              </Route>
              <Route path="/gallery" element={<Outlet />}>
                <Route index element={<Gallery />} />
                <Route
                  path="/gallery/association-detail"
                  element={<AssociationDetail />}
                />
              </Route>
              <Route path="/useful-links" element={<UsefulLinks />} />
              <Route path="/get-advice" element={<GetAdvice />} />
              <Route
                path="/get-legal-assistance"
                element={<GetLegalAssistance />}
              />
              <Route path="/buy-insurance" element={<BuyInsurance />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/ask-for-training" element={<AskTraining />} />
              <Route path="/users" element={<Users />} />
              <Route path="/events" element={<Events />} />
              <Route path="/events_details" element={<EventDetails />} />
              <Route path="/members" element={<NewMembers />} />
              <Route path="/suppliers" element={<NewSuppliers />} />
              <Route path="/downloads" element={<Downloads />} />
              <Route path="/notification" element={<Notifications />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
      {/* <FullLoader loading={true} /> */}
    </MyState>
  );
}

export default App;

export const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem("user");
  if (user) {
    return <Outlet />;
  } else {
    return <Navigate to={"/login"} />;
  }
};
