// Header.js
import React, { useState } from "react";
import { MyPopup } from "../utils/layoutUtils";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import Sidebar, { Tab } from "./Sidebar";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen1(!open1);
  };
  return (
    <div className="bg fixed top-0 left-0 bg-white h-16 w-full flex items-center justify-between px-4 z-10 shadow-md">
      <div className="h-16 items-center justify-center flex gap-5 ">
        <div className="md:hidden flex" onClick={() => setOpen1(true)}>
          <IoMdMenu
          color="#fff"
          size={30} />
        </div>
        {/* <h1 className="font-bold text-xl">

        </h1> */}
        <div className="flex justify-center items-center">
        <img className="h-14 ml-5 w-50 object-fill rounded-md"  src="/logo.png" alt={"LPG Association"}/>
        </div>
      </div>
      <h1
        className="text-lg text-white font-bold cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        Logout
      </h1>
      <MyPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        message={"Are you sure? You want to logout"}
        title={"Log Out"}
        bname={"Logout"}
        onClick={() => {
          setIsOpen(false);
          localStorage.removeItem("user");
          navigate("/login");
        }}
      />

      <div
        className={`fixed top-0 left-0 h-full w-[400px] max-w-[90vw] bg-white shadow-xl z-50 transform ${
          open1 ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="">
          <div className="flex items-center justify-between p-4">
            <h2 className="text-lg font-semibold text-black">My Card</h2>
            <IoCloseSharp className="text-black" onClick={()=>setOpen1(false)} size={20} />
          </div>
          <div>
            <Tab
              title={"Dashboard"}
              onClick={() => {
                setOpen1(false);
                navigate("/");
              }}
              path=""
            />
            <Tab
              title={"Users"}
              onClick={() => {
                setOpen1(false);
                navigate("/users");
              }}
              path="users"
            />
            <Tab
              title={"Members"}
              onClick={() => {
                setOpen1(false);
                navigate("/members");
              }}
              path="members"
            />
            <Tab
              title={"Suppliers"}
              onClick={() => {
                setOpen1(false);
                navigate("/suppliers");
              }}
              path="suppliers"
            />
            <Tab
              title={"Association Info"}
              onClick={() => {
                setOpen1(false);
                navigate("/association-info", { state: "Association Info" });
              }}
              path="association-info"
            />
            <Tab
              title={"Useful Links"}
              onClick={() => {
                setOpen1(false);
                navigate("/useful-links");
              }}
              path="useful-links"
            />
            <Tab
              title={"Gallery"}
              onClick={() => {
                setOpen1(false);
                navigate("/gallery");
              }}
              path="gallery"
            />
            <Tab
              title={"Get Advice"}
              onClick={() => {
                setOpen1(false);
                navigate("/get-advice", { state: "Get Advice" });
              }}
              path="get-advice"
            />
            <Tab
              title={"Get Legal Assistance"}
              onClick={() => {
                setOpen1(false);
                navigate("/get-legal-assistance", {
                  state: "Get Legal Assistance",
                });
              }}
              path="get-legal-assistance"
            />
            <Tab title={"Mechanic/MI"} />
            <Tab title={"Let's Shop"} />
            <Tab
              title={"Buy Insurance"}
              onClick={() => {
                setOpen1(false);
                navigate("/buy-insurance", { state: "Buy Insurance" });
              }}
              path="buy-insurance"
            />
            <Tab title={"Ask for Training"} />
            {/* <Tab title={"Messaging"} /> */}
            <Tab title={"Events"} onClick={() => {
              setOpen1(false);
              navigate("/events")}} />
            <Tab
              title={"Blogs"}
              onClick={() => {
                setOpen1(false);
                navigate("/blogs");
              }}
              path="blogs"
            />
          </div>
        </div>
      </div>

      {open1 && (
        <div
          className="fixed top-0 right-0 w-full h-full bg-black opacity-50 z-40"
          onClick={toggleDrawer}
        ></div>
      )}
    </div>
  );
};

export default Header;
