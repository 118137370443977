import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Heading,
  Loader,
  MyPopup,
  MyTabs,
  TabBar,
} from "../../../utils/layoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import myContext from "../../../context/data/myContext";
import Table from "../../../components/Table";
import {
  delete_advice,
  delete_training,
  get_advice_list,
  get_training_list,
} from "../../../utils/apis";
import { FiTrash } from "react-icons/fi";
import { toast } from "react-toastify";

const AskTraining = () => {
  const context = useContext(myContext);
  const {
    category,
    loading,
    setLoading,
    loading1,
    training_list,
    get_training_list,
    total_members_page,
    total_members,
  } = context;
  const [currentTab, setCurrentTab] = useState(1);
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [search, setSearch] = useState("");
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);

  const navigate = useNavigate();
  const [detail, setDetail] = useState({});

  async function fetchData() {
    return await get_training_list(
      category[8]?.id,
      category[8]?.sub_categories[currentTab - 1]?.id,
      page,
      row,
      search
    );
  }
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        fetchData();
      }, 300);
    } else {
      fetchData();
    }
    return () => {
      clearTimeout(delay);
    };
  }, [currentTab, row, page, search]);

  const onDelete = async () => {
    const res = await delete_training(detail.ID, setLoading2);
    if (res.success) {
      setOpen(false);
      fetchData();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="w-full block">
      <Heading title={"Ask For Training"} />
      <TabBar
        TabData={category[8]?.sub_categories}
        setActiveTab={setCurrentTab}
        activeTab={currentTab}
      />
      <Table
        noData={training_list}
        headData={[
          "S.No.",
          "Gender",
          "Location",
          "Req_member",
          "From_date",
          "To_date",
          "Actions",
        ]}
        searchValue={search}
        onSearchChange={(v) => {
          setPage(1);
          setSearch(v.target.value);
          setPrevSearch(search);
        }}
        loading={loading1}
        onRowChange={(v) => {
          setPage(1);
          setRow(v.target.value);
        }}
        total_page={total_members_page}
        page={page}
        setPage={setPage}
        total_value={total_members}
        row={row}
        pagination={true}
      >
        {training_list.map((item, index) => (
          <tr className="text-center items-center justify-center " key={index}>
            <td className="whitespace-nowrap py-4 px-3">{index + 1}</td>
            <td className="whitespace-nowrap py-4 px-3">{item.Gender}</td>
            <td className="whitespace-nowrap py-4 px-3">{item.Location}</td>
            <td className="whitespace-nowrap py-4 px-3">{item.Req_member}</td>
            <td className="whitespace-nowrap py-4 px-3">{item.From_date}</td>
            <td className="whitespace-nowrap py-4 px-3">{item.To_date}</td>

            <td className="whitespace-nowrap ">
              <div className="flex gap-2 justify-center">
                <FiTrash
                  color="#d1262b"
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setDetail(item);
                    setOpen(true);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </Table>
      <MyPopup
        isOpen={open}
        setIsOpen={setOpen}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading2}
      ></MyPopup>
    </div>
  );
};

export default AskTraining;
