import React from "react";
import { FullLoader, Loader } from "../../../utils/layoutUtils";

const Dashboard = () => {
  return <div className="w-full">Dashboard
  
  </div>;
};

export default Dashboard;
