import { Outlet } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { useContext } from "react";
import myContext from "./context/data/myContext";
import { FullLoader } from "./utils/layoutUtils";
import ScrollToTop from "./utils/Hook/ScrollToTop";

function Layout() {
  const context = useContext(myContext);
  const { loading } = context;
  return (
    <>
      <div className="flex h-screen bg-slate-50 ">
        <div className="hidden md:block   w-64 bg-white fixed top-0 bottom-0 left-0 overflow-y-auto mt-16 shadow-md">
          <Sidebar />
        </div>
        <div className="flex flex-col  w-full">
          <Header />
          {/* Content */}
          <div className=" md:ml-64 flex-1 overflow-y-auto p-4 mt-16">
      
              <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
