import React, { useContext, useEffect, useRef, useState } from "react";
import { Heading, Loader, MyTabs, TabBar } from "../../../utils/layoutUtils";
import myContext from "../../../context/data/myContext";
import { useLocation } from "react-router-dom";
import { get_insurance_list } from "../../../utils/apis";

const BuyInsurance = () => {
  const context = useContext(myContext);
  const {
    category,
    loading,
    setLoading,
    loading1,
    insurance_list,
    get_insurance_list,
    total_members_page,
    total_members,
  } = context;

  const [currentTab, setCurrentTab] = useState(1);
  const [currentTab1, setCurrentTab1] = useState(1);
  const { state } = useLocation();

  const [search, setSearch] = useState("");
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [prevSearch, setPrevSearch] = useState("");
  const isInitialRender = useRef(true);

  async function fetchData() {
    return await get_insurance_list(
      category[7]?.id,
      category[7]?.sub_categories[currentTab - 1]?.id,
      currentTab == 1
        ? "distributor_insurance.php"
        : currentTab == 2
        ? "employee_insurance.php"
        : currentTab == 3
        ? "motor_insurance.php"
        : "Health_insurance.php",
      page,
      row,
      search,
    );
  }
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        fetchData();
      }, 300);
    } else {
      fetchData();
    }
    return () => {
      clearTimeout(delay);
    };
  }, [currentTab,currentTab1, row, page, search]);

  return (
    <div className="w-full block">
      <Heading title={"Buy Insurance"} />
     
        <TabBar
          TabData={category[7]?.sub_categories}
          setActiveTab={setCurrentTab}
          activeTab={currentTab}
        />
      
      {currentTab == 4 && (
        <TabBar
          TabData={[{ name: "Life Insurance" }, { name: "Health Insurance" }]}
          setActiveTab={setCurrentTab1}
          activeTab={currentTab1}
        />
      )}
      {loading ? <Loader /> : ""}
    </div>
  );
};

export default BuyInsurance;
