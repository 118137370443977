import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Center,
  FileInput,
  Heading,
  Input,
  Loader,
  MyModal,
  SelectBox,
} from "../../../utils/layoutUtils";
import myContext from "../../../context/data/myContext";
import Table from "../../../components/Table";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import moment from "moment";
import { toast } from "react-toastify";
import {
  downloadFile,
  EventDetailsHeaders,
  validateEmail,
  validateMobile,
} from "../../../utils/Utils";
import { SwitchBox, Td } from "../../../components/SwitchBox";
import { IoMdDownload } from "react-icons/io";
import { addEdit_events } from "../../../utils/apis";
import { ReactPrintData } from "../../../components/ReactToPrint";
import ReactToPrint from "react-to-print";
import PassPrint from "../../../components/PassPrint";

const EventDetails = () => {
  const previewRef = useRef(null);

  const context = useContext(myContext);
  const {
    loading,
    total_att,
    total_att_page,
    getEventsDetails,
    events_list_details,
  } = context;
  const [active, setActive] = useState(1);
  const [detail, setdetail] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [row, setRow] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [prevSearch, setPrevSearch] = useState("");
  const { state } = useLocation();
  const [viewItem, setViewItem] = useState({});

  const obj = {
    id: state?.ID,
    type:
      active == 1
        ? "member"
        : active == 2
        ? "visitor"
        : active == 3
        ? "exhibitor"
        : active == 4
        ? "media"
        : "",
    page: page,
    limit: row,
    search: search,
  };
  const isInitialRender = useRef(true);

  console.log("total_att ===", total_att);
  console.log("total_att_page ===", total_att_page);

  useEffect(() => {
    getEventsDetails(obj);
  }, [active, page, row]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let delay;
    if (search !== prevSearch) {
      delay = setTimeout(() => {
        getEventsDetails(obj);
      }, 300);
    } else {
      getEventsDetails(obj);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [search]);

  const currentTime = moment();
  const eventDate = moment(state?.Event_Date);
  const eventTime = moment(state?.Event_Time, "hh:mm A");
  const eventDateTime = eventDate.set({
    hour: eventTime.hour(),
    minute: eventTime.minute(),
  });

  // Determine meeting status
  let meetingStatus = "";
  let meet_status = 0;
  if (
    eventDateTime.isBefore(currentTime) &&
    currentTime.isBefore(eventDate.clone().add(1, "days"))
  ) {
    meetingStatus = "Meeting Started";
    meet_status = 0;
  } else if (eventDateTime.isAfter(currentTime)) {
    meetingStatus = "Meeting Not Started Yet";
    meet_status = 1;
  } else {
    meetingStatus = "Meeting Completed";
    meet_status = 2;
  }

  // Format the scheduled date and time
  const scheduledDate = `${eventDate.format("DD-MMM-YYYY")} ${eventTime.format(
    "hh:mm A"
  )}`;

  const onAdd = async () => {
    if (
      !detail.Name ||
      detail?.Name?.trim()?.length == 0 ||
      !detail.c_name ||
      detail?.c_name?.trim()?.length == 0 ||
      !detail.email ||
      !detail.mobile ||
      !validateMobile(detail.mobile) ||
      !validateEmail(detail.email) ||
      (active == 4 && !detail.media_type)
    ) {
      setdetail({
        ...detail,
        NameMess:
          !detail.Name || detail?.Name?.trim()?.length == 0
            ? "Name is required"
            : "",
        c_nameMess:
          !detail.c_name || detail?.c_name?.trim()?.length == 0
            ? "Company Name is required"
            : "",
        media_typeMess: !detail.media_type ? "Media Type is required" : "",
        emailMess: !detail.email
          ? "Email is required"
          : !validateEmail(detail.email)
          ? "Please enter valid email"
          : "",
        mobileMess: !detail.mobile
          ? "Mobile is required"
          : !validateMobile(detail.mobile)
          ? "Please enter valid  mobile no."
          : "",
      });
      return;
    }
    try {
      const body = new FormData();
      body.append("action", "event_attendance");
      body.append("event_id", state?.ID);
      body.append(
        "type",
        active == 3
          ? "exhibitor"
          : active == 4
          ? "media"
          : active == 2
          ? "visitor"
          : ""
      );
      body.append("name", detail?.Name);
      body.append("company_name", detail?.c_name);
      body.append("email", detail?.email);
      body.append("mobile", detail?.mobile);
      body.append("designation", detail?.designation || "");
      body.append("media_type", detail?.media_type || "");
      const responce = await addEdit_events(body, setLoading1);
      if (responce.success) {
        toast.success(responce.message);
        getEventsDetails(obj);
        setOpen(false);
      } else if (!responce.success) {
        toast.error(responce.message);
      }
      setLoading1(false);
      // toast.success(`${active == 1 ? "Member" : "Visitor"} added successfully`);
    } catch (error) {
      console.log("error ===", error);
    }
  };
  const update_exhibitor_status = async () => {
    if (!detail?.reg_no) {
      setdetail({
        ...detail,
        reg_noMess: !detail?.reg_no ? "Transaction Type is required" : "",
      });
      return;
    }
    try {
      const body = new FormData();
      body.append("action", "update_registration_no");
      body.append("id", detail?.ID);
      body.append("registration_no", detail?.reg_no);
      const responce = await addEdit_events(body, setLoading1);
      if (responce.success) {
        toast.success(responce.message);
        getEventsDetails(obj);
        setOpen1(false);
      } else if (!responce.success) {
        toast.error(responce.message);
      }
      setLoading1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const update_visitor_status = async () => {
    if (
      !detail?.tr_type ||
      (detail?.tr_type == "online" && !detail.transaction_id)
    ) {
      setdetail({
        ...detail,
        tr_typeMess: !detail?.tr_type ? "Transaction Type is required" : "",
        transaction_idMess: !detail.transaction_id
          ? "Transaction Id is required"
          : "",
      });
      return;
    }
    try {
      const body = new FormData();
      body.append("action", "update_transaction");
      body.append("id", detail?.ID);
      body.append("payment_type", detail?.tr_type);
      body.append("transaction_id", detail?.transaction_id || "");
      const responce = await addEdit_events(body, setLoading1);
      if (responce.success) {
        toast.success(responce.message);
        getEventsDetails(obj);
        setOpen1(false);
      } else if (!responce.success) {
        toast.error(responce.message);
      }
      setLoading1(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full block">
      <div className="flex justify-between flex-col ">
        <div className="flex justify-between gap-2">
          <Heading title={`Events Details : ${state?.Name}`} />
          {meet_status == 2 ? (
            ""
          ) : (
            <>
              {active != 1 && (
                <Button
                  title={"Add"}
                  icon={<FiPlus size={20} />}
                  onClick={() => {
                    setdetail({});
                    setOpen(true);
                  }}
                />
              )}
            </>
          )}
        </div>
        <Heading
          size="md"
          title={`Scheduled at: ${scheduledDate} - ${meetingStatus}`}
        />
      </div>

      <div className="flex justify-start items-center gap-3 mt-3 flex-wrap">
        <Button
          onClick={() => {
            setActive(1);
            setRow(10);
            setPage(1);
            setSearch("");
          }}
          title={"Members"}
          disabled={active != 1}
        />
        <Button
          onClick={() => {
            setRow(10);
            setPage(1);
            setSearch("");
            setActive(2);
          }}
          title={"Visitor"}
          disabled={active != 2}
        />
        <Button
          onClick={() => {
            setRow(10);
            setPage(1);
            setSearch("");
            setActive(3);
          }}
          title={"Exhibitor"}
          disabled={active != 3}
        />
        <Button
          onClick={() => {
            setRow(10);
            setPage(1);
            setSearch("");
            setActive(4);
          }}
          title={"Media"}
          disabled={active != 4}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setPage(1);
            setSearch(v.target.value);
            setPrevSearch(search);
          }}
          loading={loading1}
          onRowChange={(v) => {
            setPage(1);
            setRow(v.target.value);
          }}
          total_page={total_att_page}
          page={page}
          setPage={setPage}
          total_value={total_att}
          row={row}
          pagination={true}
          setRow={setRow}
          noData={events_list_details}
          headData={
            EventDetailsHeaders?.filter((val, ind) => val?.type == active)?.[0]
              ?.header
          }
        >
          {events_list_details
            // ?.filter((item) => {
            //   return (
            //     item.Name.toLowerCase().includes(search.toLowerCase()) ||
            //     item.Venue.toLowerCase().includes(search.toLowerCase())
            //   );
            // })
            .map((item, index) => (
              <tr className="text-center" key={index}>
                {active == 1 ? (
                  <>
                    <Td>{index + 1}</Td>
                    <Td>{item.OMC_Type}</Td>
                    <Td>{item.company_name}</Td>
                    <Td>{item.agency_id}</Td>
                    <Td>{item.district}</Td>
                    <Td>{item.mobile}</Td>
                    <Td>{item.email}</Td>
                    <Td>
                      <div className="flex gap-2 justify-center">
                        {/* {item?.payment_type ? ( */}
                        <ReactPrintData
                          num={
                            <IoMdDownload
                              size={20}
                              className="cursor-pointer"
                            />
                          }
                          documentTitle={`Event-Pass`}
                          v={item}
                          previewRef={previewRef}
                          setViewItem={setViewItem}
                        />
                        {/* ) : (
                          "-"
                        )} */}
                      </div>
                    </Td>
                  </>
                ) : active == 2 ? (
                  <>
                    <Td>{index + 1}</Td>
                    <Td>{item.name}</Td>
                    <Td>{item.company_name}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.mobile}</Td>
                    <Td>{item.email}</Td>
                    <Td>{item.transaction_id || "-"}</Td>
                    <Td center>
                      {meet_status == 2 ? (
                        "-"
                      ) : item?.payment_type ? (
                        item?.payment_type
                      ) : (
                        <div className="flex gap-2">
                          <FiEdit
                            onClick={() => {
                              setOpen1(true);
                              setdetail(item);
                            }}
                            color="#2b3478"
                            size={20}
                            className="cursor-pointer"
                          />
                        </div>
                      )}
                    </Td>

                    {/* <ReactPrintData
                          num={v?.SID}
                          documentTitle={`${
                            v?.CUSTOMER_VENDOR_NAME || v?.CVO_NAME
                          }-${v?.SID}`}
                          v={v}
                          previewRef={previewRef}
                          setViewItem={setViewItem}
                        /> */}

                    <Td>
                      <div className="flex gap-2 justify-center">
                        {item?.payment_type ? (
                          <ReactPrintData
                            num={
                              <IoMdDownload
                                size={20}
                                className="cursor-pointer"
                              />
                            }
                            documentTitle={`Event-Pass`}
                            v={item}
                            previewRef={previewRef}
                            setViewItem={setViewItem}
                          />
                        ) : (
                          "-"
                        )}
                      </div>
                    </Td>
                  </>
                ) : active == 3 ? (
                  <>
                    <Td>{index + 1}</Td>
                    <Td>{item.name}</Td>
                    <Td>{item.company_name}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.mobile}</Td>
                    <Td>{item.email}</Td>
                    <Td center>
                      {item.registration_no ? (
                        item.registration_no
                      ) : meet_status == 2 ? (
                        "-"
                      ) : (
                        <FiEdit
                          onClick={() => {
                            setOpen1(true);
                            setdetail(item);
                          }}
                          color="#2b3478"
                          size={20}
                          className="cursor-pointer"
                        />
                      )}
                    </Td>
                    <Td>
                      <div className="flex gap-2 justify-center">
                        {item?.registration_no && (
                          // <IoMdDownload
                          //   onClick={() => {
                          //     downloadFile(item?.file);
                          //   }}
                          //   size={20}
                          //   className="cursor-pointer"
                          // />
                          <ReactPrintData
                            num={
                              <IoMdDownload
                                size={20}
                                className="cursor-pointer"
                              />
                            }
                            documentTitle={`Event-Pass`}
                            v={item}
                            previewRef={previewRef}
                            setViewItem={setViewItem}
                          />
                        )}
                      </div>
                    </Td>
                  </>
                ) : active == 4 ? (
                  <>
                    <Td>{index + 1}</Td>
                    <Td>{item.name}</Td>
                    <Td>
                      {item.media_type == 2 ? "Print Media" : "Digital Media"}
                    </Td>
                    <Td>{item.company_name}</Td>
                    <Td>{item.mobile}</Td>
                    <Td>{item.email}</Td>
                    <Td>
                      <div className="flex gap-2 justify-center">
                        <ReactPrintData
                          num={
                            <IoMdDownload
                              size={20}
                              className="cursor-pointer"
                            />
                          }
                          documentTitle={`Event-Pass`}
                          v={item}
                          previewRef={previewRef}
                          setViewItem={setViewItem}
                        />
                      </div>
                    </Td>
                  </>
                ) : (
                  ""
                )}
              </tr>
            ))}
        </Table>
      )}

      <MyModal
        isOpen={open}
        setIsOpen={setOpen}
        title={`Add ${
          active == 1
            ? "Member"
            : active == 2
            ? "Visitor"
            : active == 3
            ? "Exhibitor"
            : active == 4
            ? "Media"
            : ""
        }`}
      >
        {active == 4 ? (
          <div className="p-5 w-full">
            <div className="flex w-full gap-4 ">
              <Input
                label={"Full Name"}
                placeholder={"Full Name"}
                value={detail.Name}
                onChange={(v) => {
                  setdetail({ ...detail, Name: v.target.value, NameMess: "" });
                }}
                error={detail.NameMess}
              />
              <SelectBox
                label={"Media Type"}
                placeholder={"Media Type"}
                value={detail.media_type}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    media_type: v.target.value,
                    media_typeMess: "",
                  });
                }}
                error={detail.media_typeMess}
                options={
                  <>
                    <option value={"1"}>Digital Media</option>
                    <option value={"2"}>Print Media</option>
                  </>
                }
              />
            </div>
            <div className="flex w-full gap-4">
              <Input
                label={`${
                  detail?.media_type == 2 ? "Publication" : "Channel"
                } Name`}
                placeholder={`${
                  detail?.media_type == 2 ? "Publication" : "Channel"
                } Name`}
                value={detail.c_name}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    c_name: v.target.value,
                    c_nameMess: "",
                  });
                }}
                error={detail.c_nameMess}
              />
              <Input
                label={"Mobile Number"}
                placeholder={"Mobile Number"}
                value={detail.mobile}
                onChange={(v) => {
                  const input = v.target.value;
                  const mobile = input.replace(/\D/g, "").slice(0, 10);

                  setdetail({
                    ...detail,
                    mobile: mobile,
                    mobileMess: "",
                  });
                }}
                error={detail.mobileMess}
              />
            </div>

            <div className="flex w-full gap-4">
              <Input
                label={"Email"}
                placeholder={"Email"}
                value={detail.email}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    email: v.target.value,
                    emailMess: "",
                  });
                }}
                error={detail.emailMess}
              />
              <div className="flex-1" />
            </div>

            <Center>
              <Button title={"Submit"} onClick={onAdd} loading={loading1} />
            </Center>
          </div>
        ) : (
          <div className="p-5 w-full">
            <div className="flex w-full gap-4 ">
              <Input
                label={"Full Name"}
                placeholder={"Full Name"}
                value={detail.Name}
                onChange={(v) => {
                  setdetail({ ...detail, Name: v.target.value, NameMess: "" });
                }}
                error={detail.NameMess}
              />
              <Input
                label={"Company Name"}
                placeholder={"Company Name"}
                value={detail.c_name}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    c_name: v.target.value,
                    c_nameMess: "",
                  });
                }}
                error={detail.c_nameMess}
              />
            </div>
            <div className="flex w-full gap-4">
              <Input
                label={"Email"}
                placeholder={"Email"}
                value={detail.email}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    email: v.target.value,
                    emailMess: "",
                  });
                }}
                error={detail.emailMess}
              />

              <Input
                label={"Mobile Number"}
                placeholder={"Mobile Number"}
                value={detail.mobile}
                onChange={(v) => {
                  const input = v.target.value;
                  const mobile = input.replace(/\D/g, "").slice(0, 10);

                  setdetail({
                    ...detail,
                    mobile: mobile,
                    mobileMess: "",
                  });
                }}
                error={detail.mobileMess}
              />
            </div>

            <div className="flex w-full gap-4">
              <Input
                label={active == 3 ? "Role" : "Designation"}
                placeholder={active == 3 ? "Role" : "Designation"}
                value={detail.designation}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    designation: v.target.value,
                    designationMess: "",
                  });
                }}
                error={detail.designationMess}
              />
              <div className="flex-1" />
            </div>

            <Center>
              <Button title={"Submit"} onClick={onAdd} loading={loading1} />
            </Center>
          </div>
        )}
      </MyModal>

      <MyModal
        isOpen={open1}
        setIsOpen={setOpen1}
        title={
          active == 3
            ? "Add Registration No."
            : active == 2
            ? "Add Transaction Id"
            : ""
        }
        maxW="400px"
        minW="300px"
      >
        <div className="p-5 w-full ">
          {active == 3 ? (
            <>
              <Input
                label={"Registration No."}
                placeholder={"Registration No."}
                value={detail.reg_no}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    reg_no: v.target.value,
                    reg_noMess: "",
                  });
                }}
                error={detail.reg_noMess}
              />
              <Center>
                <Button
                  title={"Submit"}
                  onClick={update_exhibitor_status}
                  loading={loading1}
                />
              </Center>
            </>
          ) : (
            <>
              <SelectBox
                label={"Transaction Type"}
                placeholder={"Transaction Type"}
                value={detail.tr_type}
                onChange={(v) => {
                  setdetail({
                    ...detail,
                    tr_type: v.target.value,
                    tr_typeMess: "",
                  });
                }}
                error={detail.tr_typeMess}
                options={
                  <>
                    <option value={"cash"}>Cash</option>
                    <option value={"online"}>Online</option>
                  </>
                }
              />
              {detail.tr_type == "online" && (
                <Input
                  label={"Transaction Id"}
                  placeholder={"Transaction Id"}
                  value={detail.transaction_id}
                  onChange={(v) => {
                    setdetail({
                      ...detail,
                      transaction_id: v.target.value,
                      transaction_idMess: "",
                    });
                  }}
                  error={detail.transaction_idMess}
                />
              )}

              <Center>
                <Button
                  title={"Submit"}
                  onClick={update_visitor_status}
                  loading={loading1}
                />
              </Center>
            </>
          )}
        </div>
      </MyModal>
      <div style={{ display: "none" }}>
        <PassPrint
          previewRef={previewRef}
          data={{
            agency_name: viewItem?.name,
            omc_type: active == 1 ? viewItem?.OMC_Type : viewItem?.company_name,
            district: active == 1 ? viewItem?.district : viewItem?.designation,
            media_type:
              viewItem.media_type == 2
                ? "Print Media"
                : viewItem.media_type == 1
                ? "Digital Media"
                : "",
            media_type_name:
              viewItem.media_type == 2
                ? "Publication Name "
                : viewItem.media_type == 1
                ? "Channel Name"
                : "",
            active: active,
          }}
        />
      </div>
    </div>
  );
};

export default EventDetails;
