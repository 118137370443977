import React, { useState } from "react";
import { Dialog, Tab } from "@headlessui/react";
import { FiChevronUp } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { Oval } from "react-loader-spinner";

function FileInput({ label, error, doctype, onChange, multiple, fileName }) {
  const inputOpenFileRef = React.createRef();
  const showOpenFileDlg = () => {
    inputOpenFileRef.current.click();
  };

  return (
    <div className="mb-4 flex-1">
      <label htmlFor="email" className="block text-gray-700">
        {label}
      </label>
      <div
        className={`mt-1 flex cursor-pointer  w-full items-center resize-y rounded-md border-[1.5px]  border-gray-200 focus:border-indigo-500 ${
          error ? "border-red-500" : ""
        }`}
        onClick={showOpenFileDlg}
      >
        <label className="cursor-pointer bg hover:font-bold text-white py-1 px-4 rounded-l-md  border-[1.5px]  border-[#2b3478]">
          Browse
        </label>
        <div className="px-2">{fileName ? fileName : "Choose File"}</div>
        <input
          ref={inputOpenFileRef}
          onChange={onChange}
          multiple={multiple}
          accept={doctype || "image/png, image/jpg, image/jpeg ,.pdf"}
          id="fileInput"
          type="file"
          className="hidden"
        />
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
}

// function FileInput({ label, error, doctype, onChange, multiple, fileName }) {
//   const inputOpenFileRef = React.createRef();

//   return (
//     <div className="mb-4 flex-1">
//       <label  className="block text-gray-700">
//         {label}
//       </label>
//       <div
//         className={`mt-1 flex w-full items-center resize-y rounded-md border-[1.5px] border-gray-200 focus:border-indigo-500 ${
//           error ? "border-red-500" : ""
//         }`}

//       >
//         {/* Label triggers the input automatically without an onClick */}
//         <label
//           htmlFor="fileInput"
//           className="cursor-pointer hover:font-bold text-white py-1 px-4 rounded bg-blue-500"
//         >
//           Browse
//         </label>
//         <div  className="px-2">{fileName ? fileName : "Choose File"}</div>
//         <input
//           ref={inputOpenFileRef}
//           onChange={onChange}
//           multiple={multiple}
//           accept={doctype || "image/png, image/jpg, image/jpeg, .pdf"}
//           id="fileInput"
//           type="file"
//           className="hidden"
//         />
//       </div>
//       {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
//     </div>
//   );
// }

const Input = ({
  label,
  value,
  onChange,
  error,
  placeholder,
  area,
  type,
  disabled,
}) => {
  return (
    <div className="mb-4 flex-1">
      <label htmlFor="email" className="block text-gray-700">
        {label}
      </label>
      {area ? (
        <textarea
          disabled={disabled}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`mt-1 block w-full resize-y min-h-14 rounded-md border-[1.5px] px-3 py-1 border-gray-200 focus:border-indigo-500 ${
            error ? "border-red-500" : ""
          }`}
        />
      ) : (
        <input
          className={`mt-1 block w-full rounded-md border-[1.5px] px-3 py-1 border-gray-200 focus:border-indigo-500 ${
            error ? "border-red-500" : ""
          }`}
          value={value}
          disabled={disabled}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};
const SelectBox = ({
  label,
  value,
  onChange,
  error,
  placeholder,
  options,
  disabled,
}) => {
  return (
    <div className="mb-4 flex-1">
      <label htmlFor="selectBox" className="block text-gray-700">
        {label}
      </label>
      <select
        className={`mt-1 block w-full rounded-md border-[1.5px] px-3 py-1 border-gray-200 focus:border-indigo-500 ${
          error ? "border-red-500" : ""
        }`}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="">{placeholder}</option>
        {options}
      </select>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

function Loader1() {
  return (
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
  );
}
const Button = ({ onClick, title, icon, loading, bg, disabled = false }) => {
  return (
    <button
      // disabled={loading || disabled}
      className={`flex items-center gap-2 h-[30px] min-w-24 justify-center bg text-white py-1 px-4 font-normal rounded-md hover:bg hover:font-bold ${
        loading ? "opacity-50 cursor-not-allowed" : "cursor-pointer "
      }`}
      style={{ backgroundColor: disabled ? "gray" : bg ? bg : "" }}
      onClick={onClick}
    >
      {loading ? (
        <Oval
          visible={true}
          height="20"
          width="20"
          color="#fff"
          strokeWidth={5}
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        <>
          {icon}
          {title}
        </>
      )}
    </button>
  );
};

const Heading = ({ title, size = "xl" }) => {
  return (
    <div className={`font-medium text-slate-900 text-${size}`}>{title}</div>
  );
};
function TabBar({ activeTab = 1, setActiveTab, TabData = [] }) {
  // const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="flex mt-2">
      <div className=" w-full flex items-center self-start bg-[#c8cee2] gap-1 p-1 overflow-x-scroll">
        {TabData.map((item, index) => (
          <button
            key={index}
            className={` flex-1 whitespace-nowrap overflow-ellipsis flex px-5 py-2 justify-center ${
              activeTab === index + 1
                ? "text-[#1c4ed8] bg-white"
                : "text-white  hover:bg-[#ffffff33]"
            }`}
            onClick={() => handleTabClick(index + 1)}
          >
            {item?.name}
          </button>
        ))}
      </div>
    </div>
  );
}

function MyTabs({ setSelected, TabData = [] }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <Tab.Group className="w-full mt-1 overflow-x-scroll">
      <Tab.List className="flex space-x-1 rounded-xl bg p-1">
        {TabData.map((item, index) => (
          <Tab
            key={index}
            onChange={(v) => console.log(v)}
            onClick={() => setSelected(index + 1)}
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white text-blue-700 shadow"
                  : "text-white hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            {item?.name}
          </Tab>
        ))}
      </Tab.List>
      {/* <Tab.Panels>
        <Tab.Panel>Content 1</Tab.Panel>
        <Tab.Panel>Content 2</Tab.Panel>
        <Tab.Panel>Content 3</Tab.Panel>
      </Tab.Panels> */}
    </Tab.Group>
  );
}

const Center = ({ children }) => {
  return (
    <div className="w-full min-w-full flex flex-1 items-center justify-center">
      {children}
    </div>
  );
};

function MyPopup({
  isOpen,
  setIsOpen,
  title,
  children,
  message,
  bname,
  onClick,
  loading,
  primary,
}) {
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setIsOpen(false);
    }
  };
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center  overflow-x-hidden overflow-y-auto outline-none focus:outline-none modal-overlay "
          onClick={handleOutsideClick}
        >
          <div className="fixed inset-0 bg-black opacity-50 modal-overlay"></div>
          <div className="relative w-auto max-w-md mx-auto my-6">
            <div className="relative flex flex-col bg-white border-2 border-gray-200 shadow-md rounded-lg xl:min-w-96 md:min-w-full">
              {/* Modal header */}
              <div className="px-6 py-4 border-b border-gray-200">
                <h3 className="text-lg font-medium text-gray-900">{title}</h3>
              </div>
              {/* Modal body */}
              {children ? (
                children
              ) : (
                <div className="px-6 py-4">
                  <p className="text-md text-gray-700">{message}</p>
                </div>
              )}
              {/* Modal footer */}
              <div className="px-6 py-4 flex justify-end border-t border-gray-200">
                <button
                  className="text-sm text-white hover:text-white mr-3 bg-slate-400 py-1 px-3 rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  disabled={loading}
                  className={`text-sm justify-center  flex text-white min-w-16  py-1 px-3 rounded-md 
                  ${
                    loading
                      ? "opacity-50 cursor-not-allowed"
                      : "cursor-pointer "
                  }${
                    primary
                      ? "bg-indigo-500 hover:bg-indigo-600"
                      : "bg-red-700 hover:bg-red-800"
                  }`}
                  onClick={onClick}
                >
                  {loading ? (
                    <Oval
                      visible={true}
                      height="18"
                      width="18"
                      color="#fff"
                      strokeWidth={5}
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    bname
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
function MyModal({ isOpen, setIsOpen, title, children, maxW = "", minW = "" }) {
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setIsOpen(false);
    }
  };
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none modal-overlay scrollbar-none"
          onClick={handleOutsideClick}
        >
          <div className="fixed inset-0 bg-black opacity-50 modal-overlay"></div>
          <div
            style={{ maxWidth: maxW, minWidth: minW }}
            className="relative w-auto xl:w-[60%] mx-auto my-6 max-h-[90%] overflow-y-scroll scrollbar-none"
          >
            <div className="relative flex flex-col bg-white border-2 border-gray-200 shadow-md rounded-lg  md:min-w-full">
              {/* Modal header */}
              <div className="px-4 py-2 border-b border-gray-200 flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">{title}</h3>
                <IoMdClose
                  cursor={"pointer"}
                  color="#000"
                  size={20}
                  onClick={() => setIsOpen(false)}
                  className=""
                />
              </div>

              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full  bg-white py-6">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-700"></div>
    </div>
  );
};
const FullLoader = ({ loading }) => {
  return (
    <div
      style={{ height: "100vh", width: "100vw", position: "relative" }}
      className="relative z-10"
    >
      {loading && (
        <>
          <div className="inset-0 bg-white bg-opacity-0 backdrop-blur-sm z-40 transition-opacity duration-500 ease-in-out opacity-100"></div>
          <div
            role="status"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </>
      )}
    </div>
  );
};

const ViewData = ({ title, value }) => {
  return (
    <div className="mb-4 flex-1 block">
      <label htmlFor="email" className="block text-gray-700">
        {title}
      </label>

      <div className="text-black text-sm mt-1 py-2 px-2 bg-white">{value}</div>
    </div>
  );
};

const Spacer = ({ height }) => {
  return <div className="w-full" style={{ height: height || 10 }}></div>;
};

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative inline-block">
      <div
        className="inline-block"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && (
        <div className="absolute z-10 -mt-16 p-2 bg-gray-800 text-white rounded-md shadow-md whitespace-nowrap">
          {text}
        </div>
      )}
    </div>
  );
};

export {
  Input,
  Button,
  MyTabs,
  Heading,
  Center,
  MyPopup,
  Loader,
  FullLoader,
  MyModal,
  ViewData,
  Spacer,
  Tooltip,
  FileInput,
  TabBar,
  SelectBox,
};
