import React from "react";
import { Button, Input, Loader } from "../utils/layoutUtils";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

const Table = ({
  children,
  headData = [],
  searchValue,
  onSearchChange,
  noData = [],
  row,
  pagination,
  total_value,
  page = 1,
  setPage,
  total_page,
  onRowChange,
  loading,
}) => {
  return (
    <div className=" mt-3">
      <div className="flex items-center justify-between">
        {pagination && noData?.length != 0 ? (
          <select
            value={row}
            onChange={onRowChange}
            className="w-[100px] px-1 py-2 border-gray-200 border-[1.5px] rounded-md focus:border-indigo-500 outline-none"
          >
            <option value={10}>10</option>
            <option disabled={total_value < 50} value={50}>
              50
            </option>
            <option disabled={total_value < 100} value={100}>
              100
            </option>
          </select>
        ) : (
          <div></div>
        )}

        <input
          className={` focus-border  mt-1 block w-full max-w-96 mb-2 rounded-md border-[1.5px] px-3 py-2 border-gray-200  `}
          value={searchValue}
          onChange={onSearchChange}
          placeholder={"Search"}
        />
      </div>
      {loading ? (
        <Loader />
      ) : noData?.length == 0 ? (
        <div className="bg-white p-7 flex w-full items-center justify-center">
          <div>No Data Found</div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto ">
            <table className="table-auto min-w-full divide-y divide-gray-200 bg-white">
              <thead className="bg-slate-200">
                <tr>
                  {headData.map((item, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 ">{children}</tbody>
            </table>
          </div>
          {pagination && (
            <div className="flex gap-10  items-center p-2 bg-slate-200 justify-end">
              <div className="flex gap-2  ">
                <div className="flex font-bold text-md text-gray-500">
                  {(page - 1) * row + 1}
                </div>
                <div className="flex font-bold text-md text-gray-500">-</div>
                <div className="flex font-bold text-md text-gray-500">
                  {total_value < row * page ? total_value : row * page}
                </div>
                <div className="flex font-bold text-md text-gray-500">of</div>
                <div className="flex font-bold text-md text-gray-500">
                  {total_value}
                </div>
              </div>
              <div className="flex gap-5">
                <MdArrowBackIosNew
                  size={25}
                  color="gray"
                  className="cursor-pointer"
                  onClick={() => page == 1 || setPage(page - 1)}
                />
                <MdArrowForwardIos
                  size={25}
                  color="gray"
                  className="cursor-pointer"
                  onClick={() => page >= total_page || setPage(page + 1)}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Table;
