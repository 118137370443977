import React, { useContext, useState } from "react";
import {
  Button,
  Center,
  FileInput,
  Heading,
  Input,
  Loader,
  MyModal,
  MyPopup,
} from "../../../utils/layoutUtils";
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import myContext from "../../../context/data/myContext";
import Table from "../../../components/Table";
import {
  addEdit_events,
  delete_Downloads,
  delete_Events,
} from "../../../utils/apis";
import { toast } from "react-toastify";
import moment from "moment";

const Downloads = () => {
  const context = useContext(myContext);
  const { download_list, loading, getDownloadList } = context;
  const [open1, setOpen1] = useState(false);
  const [detail, setdetail] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const onDelete = async () => {
    const res = await delete_Downloads(detail.ID, setLoading1);
    if (res.success) {
      setOpen1(false);
      getDownloadList();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const onAdd = async () => {
    if (
      !detail.Name ||
      !detail.Event_Date ||
      !detail.Event_Time ||
      !detail.Venue ||
      (!edit && !detail.Image?.name)
    ) {
      setdetail({
        ...detail,
        NameMess: !detail.Name ? "Name is required" : "",
        Event_DateMess: !detail.Event_Date ? "Event Date is required" : "",
        Event_TimeMess: !detail.Event_Time ? "Event Time is required" : "",
        VenueMess: !detail.Venue ? "Event Venue is required" : "",
        ImageMess:
          !edit && !detail.Image?.name ? "Event Image is required" : "",
      });
      return;
    }
    const formdata = new FormData();

    edit
      ? formdata.append("action", "update")
      : formdata.append("action", "create");
    edit && formdata.append("id", detail.ID);
    formdata.append("Name", detail.Name);
    formdata.append("Event_Date", detail.Event_Date);
    formdata.append("Event_Time", detail.Event_Time);
    formdata.append("Venue", detail.Venue);
    !detail.Image?.name && formdata.append("image", detail.Image);
    const res = await addEdit_events(formdata, setLoading1);

    if (res?.success) {
      setOpen(false);
      getDownloadList();
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };
  return (
    <div className="w-full block">
      <div className="flex justify-between items-center">
        <Heading title={"Downloads"} />
        <Button
          title={"Add"}
          icon={<FiPlus size={20} />}
          onClick={() => {
            setdetail({});
            setEdit(false);
            setOpen(true);
          }}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Table
          searchValue={search}
          onSearchChange={(v) => {
            setSearch(v.target.value);
          }}
          noData={download_list}
          headData={["S.No.", "Subject", "Actions"]}
        >
          {download_list
            ?.filter((item) => {
              return item.Subject.toLowerCase().includes(search.toLowerCase());
            })
            .map((item, index) => (
              <tr
                className="text-center items-center justify-center py-4 px-3"
                key={index}
              >
                <td className="whitespace-nowrap py-4 px-3">{index + 1}</td>
                <td className="whitespace-nowrap py-4 px-3">{item.Subject}</td>

                <td className="whitespace-nowrap py-4 px-3 ">
                  <div className="flex gap-2 justify-center">
                    <FiEdit 
color="#2b3478"
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setEdit(true);
                        setOpen(true);
                      }}
                    />
                    <FiTrash 
color="#d1262b"
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setdetail(item);
                        setOpen1(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </Table>
      )}
      <MyModal isOpen={open} setIsOpen={setOpen} title={edit ? "Edit" : "Add"}>
        <div className="p-5 w-full">
          <div className="flex w-full gap-4">
            <Input
              label={"Event Name"}
              placeholder={"Event Name"}
              value={detail.Name}
              onChange={(v) => {
                setdetail({ ...detail, Name: v.target.value, NameMess: "" });
              }}
              error={detail.NameMess}
            />
            <Input
              label={"Venue"}
              placeholder={"Venue"}
              value={detail.Venue}
              onChange={(v) => {
                setdetail({ ...detail, Venue: v.target.value, VenueMess: "" });
              }}
              error={detail.VenueMess}
            />
          </div>
          <div className="flex w-full gap-4">
            <Input
              label={"Date"}
              placeholder={"Date"}
              type="date"
              value={detail.Event_Date}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  Event_Date: v.target.value,
                  Event_DateMess: "",
                });
              }}
              error={detail.Event_DateMess}
            />
            <Input
              label={"Time"}
              placeholder={"Time"}
              type="time"
              value={moment(detail.Event_Time, "hh:mm").format("hh:mm:ss")}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  Event_Time: v.target.value,
                  Event_TimeMess: "",
                });
              }}
              error={detail.Event_TimeMess}
            />
          </div>

          <div className="flex w-full gap-4">
            <FileInput
              doctype={".png,.jpg,.jpeg"}
              label={"Banner"}
              placeholder={"Banner"}
              fileName={detail.Image?.name}
              onChange={(v) => {
                setdetail({
                  ...detail,
                  Image: v.target.files[0],
                  ImageMess: "",
                });
              }}
              error={detail.ImageMess}
            />
            <div className="flex-1" />
          </div>

          <Center>
            <Button
              title={edit ? "Update" : "Submit"}
              onClick={onAdd}
              loading={loading1}
            />
          </Center>
        </div>
      </MyModal>
      <MyPopup
        isOpen={open1}
        setIsOpen={setOpen1}
        title={"Delete"}
        message={"Are you sure? You want to delete data"}
        bname={"Delete"}
        onClick={() => onDelete()}
        loading={loading1}
      ></MyPopup>
    </div>
  );
};

export default Downloads;
